@media (max-width: 575.98px) {
    footer.main-site-footer {
        width: 100%;
        background-color: black;
        padding-top: 50px;
        padding-bottom: 50px;
        box-sizing: border-box;
    }
    
    ul.main-site-footer-listing-ul {
        margin: 0;
        padding: 0;
        margin-left: 40px;
        margin-right: 40px;
        list-style-type: none;
    }
    
    li.main-site-footer-logo-li {
        float: left;
        width: 100%;
    }
    
    ul.main-site-footer-logo-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-inner-logo-li {
        float: left;
        width: 100%;
    }
    
    img.main-site-footer-logo {
        width: 150px;
    }
    
    li.main-site-footer-logo-info-li {
        float: left;
        width: 100%;
        margin-top: 20px;
    }
    
    p.main-site-footer-info-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        font-size: 17px;
    }
    
    li.main-site-footer-links-li {
        float: left;
        width: 100%;
    }
    
    ul.main-site-footer-inner-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 20px;
        margin-bottom: 40px;
        overflow: hidden;
    }
    
    li.main-site-footer-inner-links-block-li {
        width: 100%;
        float: left;
        margin-top: 20px;
    }
    
    ul.main-site-footer-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-link-item-li {
        width: 100%;
        margin-bottom: 10px;
    }
    
    a.main-site-footer-link-href-a {
        text-decoration: none;
        color: white;
    }
    
    h4.main-site-footer-title-h4 {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    p.main-site-footer-link-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 18px;
        margin: 0;
        padding: 0;
    }
    
    ul.main-site-footer-copyright-ul {
        width: 70%;
        margin: 0;
        padding: 0;
        margin-left: 15%;
        margin-right: 15%;
        margin-top: 50px;
    }
    
    p.main-site-footer-copyright-txt-p {
        color: white;
        font-family: 'SF Pro Display';
    }
    
    h1.main-page-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 50px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }
    
    h1.main-page-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 37px;
    }
    
    div.main-site-social-item-div {
        width: 50px;
        height: 50px;
        float: left;
        margin-right: 10px;
    }
    
    a.main-site-footer-social-item-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-site-footer-social-item-img {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    footer.main-site-footer {
        width: 100%;
        background-color: black;
        padding-top: 50px;
        padding-bottom: 50px;
        box-sizing: border-box;
    }
    
    ul.main-site-footer-listing-ul {
        margin: 0;
        padding: 0;
        margin-left: 40px;
        margin-right: 40px;
        list-style-type: none;
        overflow: hidden;
    }
    
    li.main-site-footer-logo-li {
        float: left;
        width: 100%;
    }
    
    ul.main-site-footer-logo-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-inner-logo-li {
        float: left;
        width: 100%;
    }
    
    img.main-site-footer-logo {
        width: 150px;
    }
    
    li.main-site-footer-logo-info-li {
        float: left;
        width: 100%;
        margin-top: 20px;
    }
    
    p.main-site-footer-info-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        font-size: 17px;
    }
    
    li.main-site-footer-links-li {
        float: left;
        width: 100%;
    }
    
    ul.main-site-footer-inner-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 20px;
        margin-bottom: 40px;
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 1fr;

    }
    
    li.main-site-footer-inner-links-block-li {
        width: 100%;
        float: left;
        margin-top: 20px;
    }
    
    ul.main-site-footer-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-link-item-li {
        width: 100%;
        margin-bottom: 10px;
    }
    
    a.main-site-footer-link-href-a {
        text-decoration: none;
        color: white;
    }
    
    h4.main-site-footer-title-h4 {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    p.main-site-footer-link-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 18px;
        margin: 0;
        padding: 0;
    }
    
    ul.main-site-footer-copyright-ul {
        width: 70%;
        margin: 0;
        padding: 0;
        margin-left: 15%;
        margin-right: 15%;
        margin-top: 50px;
    }
    
    p.main-site-footer-copyright-txt-p {
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
    }
    
    h1.main-page-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 50px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }
    
    h1.main-page-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 37px;
    }
    
    div.main-site-social-item-div {
        width: 50px;
        height: 50px;
        float: left;
        margin-right: 10px;
    }
    
    a.main-site-footer-social-item-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-site-footer-social-item-img {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    footer.main-site-footer {
        width: 100%;
        background-color: black;
        padding-top: 50px;
        padding-bottom: 50px;
        box-sizing: border-box;
    }
    
    ul.main-site-footer-listing-ul {
        margin: 0;
        padding: 0;
        margin-left: 40px;
        margin-right: 40px;
        list-style-type: none;
        overflow: hidden;
    }
    
    li.main-site-footer-logo-li {
        float: left;
        width: 100%;
    }
    
    ul.main-site-footer-logo-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-inner-logo-li {
        float: left;
        width: 100%;
    }
    
    img.main-site-footer-logo {
        width: 150px;
    }
    
    li.main-site-footer-logo-info-li {
        float: left;
        width: 100%;
        margin-top: 20px;
    }
    
    p.main-site-footer-info-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        font-size: 17px;
    }
    
    li.main-site-footer-links-li {
        float: left;
        width: 100%;
    }
    
    ul.main-site-footer-inner-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 20px;
        margin-bottom: 40px;
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 1fr;

    }
    
    li.main-site-footer-inner-links-block-li {
        width: 100%;
        float: left;
        margin-top: 20px;
    }
    
    ul.main-site-footer-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-link-item-li {
        width: 100%;
        margin-bottom: 10px;
    }
    
    a.main-site-footer-link-href-a {
        text-decoration: none;
        color: white;
    }
    
    h4.main-site-footer-title-h4 {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    p.main-site-footer-link-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 18px;
        margin: 0;
        padding: 0;
    }
    
    ul.main-site-footer-copyright-ul {
        width: 70%;
        margin: 0;
        padding: 0;
        margin-left: 15%;
        margin-right: 15%;
        margin-top: 50px;
    }
    
    p.main-site-footer-copyright-txt-p {
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
    }
    
    h1.main-page-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 50px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }
    
    h1.main-page-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 37px;
    }
    
    div.main-site-social-item-div {
        width: 50px;
        height: 50px;
        float: left;
        margin-right: 10px;
    }
    
    a.main-site-footer-social-item-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-site-footer-social-item-img {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    footer.main-site-footer {
        width: 100%;
        height: 500px;
        background-color: black;
        padding-top: 50px;
        box-sizing: border-box;
    }
    
    ul.main-site-footer-listing-ul {
        margin: 0;
        padding: 0;
        margin-left: 15%;
        margin-right: 15%;
        list-style-type: none;
        height: calc(100% - 80px);
    }
    
    li.main-site-footer-logo-li {
        float: left;
        width: 30%;
    }
    
    ul.main-site-footer-logo-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-inner-logo-li {
        float: left;
        width: 100%;
    }
    
    img.main-site-footer-logo {
        width: 100%;
    }
    
    li.main-site-footer-logo-info-li {
        float: left;
        width: 100%;
        margin-top: 20px;
    }
    
    p.main-site-footer-info-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        font-size: 17px;
    }
    
    li.main-site-footer-links-li {
        float: left;
        width: 70%;
    }
    
    ul.main-site-footer-inner-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-left: 50px;
        padding-top: 110px;
        list-style-type: none;
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    li.main-site-footer-inner-links-block-li {
        float: left;
    }
    
    ul.main-site-footer-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-link-item-li {
        width: 100%;
        margin-bottom: 10px;
    }
    
    a.main-site-footer-link-href-a {
        text-decoration: none;
        color: white;
    }
    
    h4.main-site-footer-title-h4 {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    p.main-site-footer-link-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 18px;
        margin: 0;
        padding: 0;
    }
    
    ul.main-site-footer-copyright-ul {
        width: 70%;
        margin: 0;
        padding: 0;
        margin-left: 15%;
        margin-right: 15%;
    }
    
    p.main-site-footer-copyright-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        text-align: center;
    }
    
    h1.main-page-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 50px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }
    
    h1.main-page-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 37px;
    }
    
    div.main-site-social-item-div {
        width: 50px;
        height: 50px;
        float: left;
        margin-right: 10px;
    }
    
    a.main-site-footer-social-item-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-site-footer-social-item-img {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 1200px) {
    footer.main-site-footer {
        width: 100%;
        height: 500px;
        background-color: black;
        padding-top: 50px;
        box-sizing: border-box;
    }
    
    ul.main-site-footer-listing-ul {
        margin: 0;
        padding: 0;
        margin-left: 15%;
        margin-right: 15%;
        list-style-type: none;
        height: calc(100% - 80px);
    }
    
    li.main-site-footer-logo-li {
        float: left;
        width: 20%;
    }
    
    ul.main-site-footer-logo-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-inner-logo-li {
        float: left;
        width: 100%;
    }
    
    img.main-site-footer-logo {
        width: 100%;
    }
    
    li.main-site-footer-logo-info-li {
        float: left;
        width: 100%;
        margin-top: 20px;
    }
    
    p.main-site-footer-info-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        font-size: 17px;
    }
    
    li.main-site-footer-links-li {
        float: left;
        width: 80%;
    }
    
    ul.main-site-footer-inner-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-left: 50px;
        padding-top: 110px;
        list-style-type: none;
    }
    
    li.main-site-footer-inner-links-block-li {
        width: calc(100% / 3);
        float: left;
    }
    
    ul.main-site-footer-links-listing-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-site-footer-link-item-li {
        width: 100%;
        margin-bottom: 10px;
    }
    
    a.main-site-footer-link-href-a {
        text-decoration: none;
        color: white;
    }
    
    h4.main-site-footer-title-h4 {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    p.main-site-footer-link-txt-p {
        color: white;
        font-family: 'SF Pro Display';
        font-size: 18px;
        margin: 0;
        padding: 0;
    }
    
    ul.main-site-footer-copyright-ul {
        width: 70%;
        margin: 0;
        padding: 0;
        margin-left: 15%;
        margin-right: 15%;
    }
    
    p.main-site-footer-copyright-txt-p {
        color: white;
        font-family: 'SF Pro Display';
    }
    
    h1.main-page-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 50px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }
    
    h1.main-page-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 37px;
    }
    
    div.main-site-social-item-div {
        width: 50px;
        height: 50px;
        float: left;
        margin-right: 10px;
    }
    
    a.main-site-footer-social-item-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-site-footer-social-item-img {
        width: 100%;
        height: 100%;
    }
}