@media (max-width: 575.98px) {
    .hamburger-menu-container {
        float: right;
    }
    
    .hamburger-icon {
        width: 40px;
        height: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }

    .hamburger-icon.dark .line {
        background-color: #000;
    }
    
    .line {
        width: 100%;
        height: 4px;
        background-color: white;
        border-radius: 2px;
    }
    
    .menu {
        position: fixed;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: none;
        width: 100%;
        z-index: -1;
        padding: 0 40px;
        padding-top: 100px;
        height: 100vh;
    }
    
    .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    .menu li {
        padding: 10px 0;
    }
    
    .menu li a {
        text-decoration: none;
        color: #ffffff;
    }
    button.hamburger-menu-btn {
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 700;
        font-size: 2.5em;
        border-bottom: 5px solid transparent;
    }
    
    button.hamburger-menu-btn:hover, button.button.hamburger-menu-btn:focus {
        border-color: rgb(255, 255, 255);
    }
    
    p.hamburger-menu-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2em;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    button.hamburger-login-btn {
        background-color: #005eff;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 700;
        height: 40px;
        width: 100%;
    }
    
    button.hamburger-login-btn:hover {
        background-color: #0046d5;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .hamburger-menu-container {
        float: right;
    }
    
    .hamburger-icon {
        width: 40px;
        height: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }

    .hamburger-icon.dark .line {
        background-color: #000;
    }
    
    .line {
        width: 100%;
        height: 4px;
        background-color: white;
        border-radius: 2px;
    }
    
    .menu {
        position: fixed;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: none;
        width: 100%;
        z-index: -1;
        padding: 0 40px;
        padding-top: 100px;
        height: 100vh;
    }
    
    .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    .menu li {
        padding: 10px 0;
    }
    
    .menu li a {
        text-decoration: none;
        color: #ffffff;
    }
    button.hamburger-menu-btn {
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 700;
        font-size: 2.5em;
        border-bottom: 5px solid transparent;
    }
    
    button.hamburger-menu-btn:hover, button.button.hamburger-menu-btn:focus {
        border-color: rgb(255, 255, 255);
    }
    
    p.hamburger-menu-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2em;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    button.hamburger-login-btn {
        background-color: #005eff;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 700;
        height: 40px;
        width: 100%;
    }
    
    button.hamburger-login-btn:hover {
        background-color: #0046d5;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .hamburger-menu-container {
        float: right;
    }
    
    .hamburger-icon {
        width: 40px;
        height: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }
    
    .line {
        width: 100%;
        height: 4px;
        background-color: #000;
        border-radius: 2px;
    }
    
    .menu {
        position: absolute;
        top: 0;
        right: 0;
        background-color: white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        display: none;
        width: 100%;
        z-index: -1;
        padding: 0 40px;
        padding-top: 100px;
    
    }
    
    .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    .menu li {
        padding: 20px 0;
    }
    
    .menu li a {
        text-decoration: none;
        color: #000;
    }
    button.hamburger-menu-btn {
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 700;
        height: 40px;
        width: 100%;
    }
    
    button.hamburger-menu-btn:hover, button.button.hamburger-menu-btn:focus {
        background-color: rgb(239, 239, 239);
    }
    
    p.hamburger-menu-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    button.hamburger-login-btn {
        background-color: #005eff;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 700;
        height: 40px;
        width: 100%;
    }
    
    button.hamburger-login-btn:hover {
        background-color: #0046d5;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .hamburger-menu-container {
        float: right;
    }
    
    .hamburger-icon {
        width: 40px;
        height: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }
    
    .line {
        width: 100%;
        height: 4px;
        background-color: #000;
        border-radius: 2px;
    }
    
    .menu {
        position: absolute;
        top: 0;
        right: 0;
        background-color: white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        display: none;
        width: 100%;
        z-index: -1;
        padding: 0 25%;
        padding-top: 100px;
    
    }
    
    .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    .menu li {
        padding: 20px 0;
    }
    
    .menu li a {
        text-decoration: none;
        color: #000;
    }
    button.hamburger-menu-btn {
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 700;
        height: 40px;
        width: 100%;
    }
    
    button.hamburger-menu-btn:hover, button.button.hamburger-menu-btn:focus {
        background-color: rgb(239, 239, 239);
    }
    
    p.hamburger-menu-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    button.hamburger-login-btn {
        background-color: #005eff;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 700;
        height: 40px;
        width: 100%;
    }
    
    button.hamburger-login-btn:hover {
        background-color: #0046d5;
    }
}

@media (min-width: 1200px) {
    .hamburger-menu-container {
        float: right;
        display: none;
    }
}