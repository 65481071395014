@media (max-width: 575.98px) {
    div.index-hero-area-div {
        width: 100%;
        min-height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 40px;
        padding-right: 40px;
    }

    .background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        z-index: -1; 
        filter: brightness(0.75) grayscale(0.5);
      }

    /* div.index-hero-area-div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../Assets/Img/mogee-achtergrond.jpg');
        background-color: white;
        background-size: cover;
        filter: brightness(0.75);
        background-position: center;
    } */

    div.index-hero-inner-div {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    div.index-hero-text-div {
        color: white;
        margin: 100px 0;
    }

    h1.index-hero-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 2.5em;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
        min-height: 50px;
    }

    p.index-hero-subtitle-p {
        font-family: 'SF Pro Display';
        font-size: 1.8em;
        font-weight: bold;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    p.index-hero-small-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.4em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        line-height: 1.2;
    }

    button.index-hero-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        background-color: white;
        border-radius: 10px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-hero-btn:hover {
        background-color: #e6e6e6;
        transition: 200ms;
        transition-property: background-color;
    }

    div.index-hero-quick-info-area-div {
        min-height: 50px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    div.index-hero-quick-info-item-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 20px;
    }

    p.index-hero-quick-info-value-txt-p {
        font-family: 'SF Pro Display';
        font-size: 2em;
        color: white;
        margin: 0;
        padding: 0;

    }

    /* span.index-hero-quick-info-value-span {
        color: rgb(3, 127, 115);
    } */

    p.index-hero-quick-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.1em;
        color: white;
        margin: 0;
        padding: 0;
        font-weight: bold;
        margin-bottom: 20px;

    }

    section.index-portfolio-section {
        width: 100%;
        padding: 50px 20px;
        overflow: hidden;
    }

    h1.index-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }

    ul.index-portfolio-listing-ul {
        width: 100%;
        min-height: 100px;
        list-style-type: none;

    }

    section.index-services-section {
        width: 100%;
    }

    div.index-services-inner-div {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        width: 90%;
        margin: 0 auto;
        margin-top: 50px;
    }

    div.index-services-item-div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        transition: 200ms;
        transition-property: box-shadow;
        padding: 50px;
    }

    .index-services-item-icon {
        font-size: 100px;
        color: black;
    }

    h2.index-services-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
    }

    p.index-services-item-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    section.index-workflow-section {
        width: 100%;
        min-height: 100vh;
        padding: 20px;
        overflow: hidden;
        box-sizing: border-box;
        margin: 50px 0;
    }

    div.index-workflow-img-area-div {
        width: 50%;
        float: left;
        min-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }

    img.index-workflow-img {
        width: 100%;
        padding-right: 100px;
        box-sizing: border-box;
    }

    div.index-workflow-txt-area-div {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    h2.page-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3em;
        font-weight: bold;
    }

    ul.index-workflow-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.index-workflow-item-li {
        width: 100%;
        min-height: 70px;
        position: relative;
        padding-left: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;

    }

    div.index-workflow-step-div {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
    }

    div.index-workflow-inner-step-div {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: relative;
        border: 3px solid black;
        transition: 500ms;
        transition-property: border;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li.index-workflow-item-li.active div.index-workflow-inner-step-div {
        border: 3px solid #04826f;
        transition: 500ms;
        transition-property: border;
    }

    .index-workflow-icon {
        width: 100%;
        text-align: center;
        color: black;
        font-size: 40px;
        bottom: 5px;
        transition: 500ms;
        transition-property: color;
    }

    li.index-workflow-item-li.active span.index-workflow-step-icon-span {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    div.index-workflow-txt-div {
        width: 100%;
        padding-top: 10px;
        min-height: 60px;
        overflow: hidden;
    }

    div.index-workflow-txt-div:after {
        top: 4.5rem;
        bottom: 0;
        left: calc(2rem - 0.30rem);
        width: .25rem;
        background-color: #b4b4b4;
        content: '';
        position: absolute;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li.active div.index-workflow-txt-div:after {
        background-color: #04826f;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li:last-child div.index-workflow-txt-div:after {
        display: none;
    }

    h3.index-workflow-step-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1em;
        line-height: 1;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        transition: 500ms;
        transition-property: color;
        font-weight: bold;
    }

    li.index-workflow-item-li.active h3.index-workflow-step-title-h3 {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    p.index-workflow-step-txt-p {
        font-family: 'SF Pro Display';
        font-size: 0.8em;
        color: black;
        margin: 0;
        padding: 0;
    }

    section.index-cta-section {
        background-color: black;
        padding: 50px 20px;
    }

    h1.cta {
        color: white;
        line-height: 1;
        font-size: 1.5em;
    }

    p.index-cta-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    div.index-cta-hoewewerken-area {
        width: 100%;
        min-height: 100px;
        position: relative;
        margin-top: 50px;
        float: left;
    }

    div.index-cta-hoewewerken-item-cards {
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        float: left;
        width: 100%;
        margin-bottom: 20px;
    }

    div.index-cta-hoewewerken-item-cards:first-child {
        margin-left: 0;
    }

    div.index-cta-hoewewerken-top-area {
        width: 100%;
        height: 50px;
    }

    span.index-cta-hoewewerken-step-span {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: #017101;
        margin: 0;
        padding: 0;
        font-weight: bold;
        padding: 5px 20px;
        display: inline-block;
        background-color: #b2f7b6;
        border-radius: 50px;
    }

    div.index-cta-hoewewerken-content-area {
        width: 100%;
        min-height: 100px;
    }

    h4.index-cta-hoewewerken-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
        line-height: 1;
    }

    p.index-cta-hoewewerken-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;

    }

    section.index-aboutus-section {
        width: 100%;
        min-height: 100px;
        padding: 0 20px;
        margin: 50px 0;
        overflow: hidden;
    }

    div.index-cta-buttons-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.index-cta-button {
        font-family: 'SF Pro Display';
        font-size: 1.3em;
        color: black;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        border-radius: 50px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;

    }

    button.index-cta-button.improve {
        color: white;
        border: 3px solid white;
    }

    button.index-cta-button.improve:hover {
        background-color: white;
        color: black;
        transition: 200ms;
        transition-property: background-color;
        order: 1px solid black;
    }

    button.index-cta-button.scratch {
        background-color: white;
        color: black;
    }

    button.index-cta-button.scratch:hover {
        background-color: rgb(226, 226, 226);
        transition: 200ms;
        transition-property: background-color;
    }

    button.index-cta-button:last-child {
        margin-left: 20px;
    }

    div.index-overons-img-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    img.index-overons-img {
        width: 100%;
        height: auto;
        border-radius: 20px;
        margin-bottom: 50px;
    }

    div.index-overons-txt-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: inherit;
    }

    div.index-overons-inner-txt-div {
        width: 100%;
        min-height: 100px;
    }

    p.index-overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }

    button.index-overons-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-overons-btn:hover {
        background-color: white;
        transition: 200ms;
        transition-property: background-color;
        color: black;
        border-color: black;
        border: 1px solid black;
    }

    h1.overons {
        text-align: left;
    }

    section.index-partners-section {
        width: 100%;
        min-height: 100px;
        padding: 25px 0px;
    }

    div.index-partner-inner-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
        margin-top: 30px;
    }

    div.index-partner-item-div {
        height: 20px;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.index-partner-item-img {
        width: auto;
        height: 100%;
        filter: grayscale(100%);
    }

    img.index-partner-item-img:hover {
        filter: grayscale(0%);
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.index-hero-area-div {
        width: 100%;
        min-height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        z-index: -1; 
        filter: brightness(0.75) grayscale(0.5);
      }

    /* div.index-hero-area-div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../Assets/Img/mogee-achtergrond.jpg');
        background-color: white;
        background-size: cover;
        filter: brightness(0.75);
        background-position: center;
    } */

    div.index-hero-inner-div {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    div.index-hero-text-div {
        color: white;
        margin: 100px 0;
    }

    h1.index-hero-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 2em;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    p.index-hero-subtitle-p {
        font-family: 'SF Pro Display';
        font-size: 1.8em;
        font-weight: bold;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    p.index-hero-small-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.4em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        line-height: 1.2;
    }

    button.index-hero-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        background-color: white;
        border-radius: 10px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-hero-btn:hover {
        background-color: #e6e6e6;
        transition: 200ms;
        transition-property: background-color;
    }

    div.index-hero-quick-info-area-div {
        min-height: 50px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    div.index-hero-quick-info-item-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 20px;
    }

    p.index-hero-quick-info-value-txt-p {
        font-family: 'SF Pro Display';
        font-size: 2em;
        color: white;
        margin: 0;
        padding: 0;

    }

    /* span.index-hero-quick-info-value-span {
        color: rgb(3, 127, 115);
    } */

    p.index-hero-quick-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.1em;
        color: white;
        margin: 0;
        padding: 0;
        font-weight: bold;
        margin-bottom: 20px;

    }

    section.index-portfolio-section {
        width: 100%;
        padding: 50px 20px;
        overflow: hidden;
    }

    h1.index-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }

    ul.index-portfolio-listing-ul {
        width: 100%;
        min-height: 100px;
        list-style-type: none;

    }

    section.index-services-section {
        width: 100%;
    }

    div.index-services-inner-div {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        width: 90%;
        margin: 0 auto;
        margin-top: 50px;
    }

    div.index-services-item-div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        transition: 200ms;
        transition-property: box-shadow;
        padding: 50px;
    }

    .index-services-item-icon {
        font-size: 100px;
        color: black;
    }

    h2.index-services-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
    }

    p.index-services-item-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    section.index-workflow-section {
        width: 100%;
        min-height: 100vh;
        padding: 20px;
        overflow: hidden;
        box-sizing: border-box;
        margin: 50px 0;
    }

    div.index-workflow-img-area-div {
        width: 50%;
        float: left;
        min-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }

    img.index-workflow-img {
        width: 100%;
        padding-right: 100px;
        box-sizing: border-box;
    }

    div.index-workflow-txt-area-div {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    h2.page-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3em;
        font-weight: bold;
    }

    ul.index-workflow-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.index-workflow-item-li {
        width: 100%;
        min-height: 70px;
        position: relative;
        padding-left: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;

    }

    div.index-workflow-step-div {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
    }

    div.index-workflow-inner-step-div {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: relative;
        border: 3px solid black;
        transition: 500ms;
        transition-property: border;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li.index-workflow-item-li.active div.index-workflow-inner-step-div {
        border: 3px solid #04826f;
        transition: 500ms;
        transition-property: border;
    }

    .index-workflow-icon {
        width: 100%;
        text-align: center;
        color: black;
        font-size: 40px;
        bottom: 5px;
        transition: 500ms;
        transition-property: color;
    }

    li.index-workflow-item-li.active span.index-workflow-step-icon-span {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    div.index-workflow-txt-div {
        width: 100%;
        padding-top: 10px;
        min-height: 60px;
        overflow: hidden;
    }

    div.index-workflow-txt-div:after {
        top: 4.5rem;
        bottom: 0;
        left: calc(2rem - 0.30rem);
        width: .25rem;
        background-color: #b4b4b4;
        content: '';
        position: absolute;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li.active div.index-workflow-txt-div:after {
        background-color: #04826f;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li:last-child div.index-workflow-txt-div:after {
        display: none;
    }

    h3.index-workflow-step-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1em;
        line-height: 1;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        transition: 500ms;
        transition-property: color;
        font-weight: bold;
    }

    li.index-workflow-item-li.active h3.index-workflow-step-title-h3 {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    p.index-workflow-step-txt-p {
        font-family: 'SF Pro Display';
        font-size: 0.8em;
        color: black;
        margin: 0;
        padding: 0;
    }

    section.index-cta-section {
        background-color: black;
        padding: 50px 20px;
    }

    h1.cta {
        color: white;
        line-height: 1;
        font-size: 1.5em;
    }

    p.index-cta-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    div.index-cta-hoewewerken-area {
        width: 100%;
        min-height: 100px;
        position: relative;
        margin-top: 50px;
        float: left;
    }

    div.index-cta-hoewewerken-item-cards {
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        float: left;
        width: 100%;
        margin-bottom: 20px;
    }

    div.index-cta-hoewewerken-item-cards:first-child {
        margin-left: 0;
    }

    div.index-cta-hoewewerken-top-area {
        width: 100%;
        height: 50px;
    }

    span.index-cta-hoewewerken-step-span {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: #017101;
        margin: 0;
        padding: 0;
        font-weight: bold;
        padding: 5px 20px;
        display: inline-block;
        background-color: #b2f7b6;
        border-radius: 50px;
    }

    div.index-cta-hoewewerken-content-area {
        width: 100%;
        min-height: 100px;
    }

    h4.index-cta-hoewewerken-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
        line-height: 1;
    }

    p.index-cta-hoewewerken-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;

    }

    section.index-aboutus-section {
        width: 100%;
        min-height: 100px;
        padding: 0 20px;
        margin: 50px 0;
        overflow: hidden;
    }

    div.index-cta-buttons-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.index-cta-button {
        font-family: 'SF Pro Display';
        font-size: 1.3em;
        color: black;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        border-radius: 50px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;

    }

    button.index-cta-button.improve {
        color: white;
        border: 3px solid white;
    }

    button.index-cta-button.improve:hover {
        background-color: white;
        color: black;
        transition: 200ms;
        transition-property: background-color;
        order: 1px solid black;
    }

    button.index-cta-button.scratch {
        background-color: white;
        color: black;
    }

    button.index-cta-button.scratch:hover {
        background-color: rgb(226, 226, 226);
        transition: 200ms;
        transition-property: background-color;
    }

    button.index-cta-button:last-child {
        margin-left: 20px;
    }

    div.index-overons-img-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    img.index-overons-img {
        width: 100%;
        height: auto;
        border-radius: 20px;
        margin-bottom: 50px;
    }

    div.index-overons-txt-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: inherit;
    }

    div.index-overons-inner-txt-div {
        width: 100%;
        min-height: 100px;
    }

    p.index-overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }

    button.index-overons-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-overons-btn:hover {
        background-color: white;
        transition: 200ms;
        transition-property: background-color;
        color: black;
        border-color: black;
        border: 1px solid black;
    }

    h1.overons {
        text-align: left;
    }

    section.index-partners-section {
        width: 100%;
        min-height: 100px;
        padding: 25px 0px;
    }

    div.index-partner-inner-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
        margin-top: 30px;
    }

    div.index-partner-item-div {
        height: 20px;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.index-partner-item-img {
        width: auto;
        height: 100%;
        filter: grayscale(100%);
    }

    img.index-partner-item-img:hover {
        filter: grayscale(0%);
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.index-hero-area-div {
        width: 100%;
        min-height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 40px;
        padding-right: 40px;
    }

    .background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        z-index: -1; 
        filter: brightness(0.75) grayscale(0.5);
      }

    /* div.index-hero-area-div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../Assets/Img/mogee-achtergrond.jpg');
        background-color: white;
        background-size: cover;
        filter: brightness(0.75);
        background-position: center;
    } */

    div.index-hero-inner-div {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    div.index-hero-text-div {
        color: white;
        margin: 100px 0;
    }

    h1.index-hero-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 3em;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
        min-height: 50px;
    }

    p.index-hero-subtitle-p {
        font-family: 'SF Pro Display';
        font-size: 1.8em;
        font-weight: bold;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    p.index-hero-small-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.4em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        line-height: 1.2;
    }

    button.index-hero-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        background-color: white;
        border-radius: 10px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-hero-btn:hover {
        background-color: #e6e6e6;
        transition: 200ms;
        transition-property: background-color;
    }

    div.index-hero-quick-info-area-div {
        min-height: 50px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    div.index-hero-quick-info-item-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 20px;
    }

    p.index-hero-quick-info-value-txt-p {
        font-family: 'SF Pro Display';
        font-size: 2em;
        color: white;
        margin: 0;
        padding: 0;

    }

    /* span.index-hero-quick-info-value-span {
        color: rgb(3, 127, 115);
    } */

    p.index-hero-quick-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.1em;
        color: white;
        margin: 0;
        padding: 0;
        font-weight: bold;
        margin-bottom: 20px;

    }

    section.index-portfolio-section {
        width: 100%;
        padding: 50px 20px;
        overflow: hidden;
    }

    h1.index-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }

    ul.index-portfolio-listing-ul {
        width: 100%;
        min-height: 100px;
        list-style-type: none;

    }

    section.index-services-section {
        width: 100%;
    }

    div.index-services-inner-div {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        width: 90%;
        margin: 0 auto;
        margin-top: 50px;
    }

    div.index-services-item-div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        transition: 200ms;
        transition-property: box-shadow;
        padding: 50px;
    }

    .index-services-item-icon {
        font-size: 100px;
        color: black;
    }

    h2.index-services-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
    }

    p.index-services-item-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    section.index-workflow-section {
        width: 100%;
        min-height: 100vh;
        padding: 20px;
        overflow: hidden;
        box-sizing: border-box;
        margin: 50px 0;
    }

    div.index-workflow-img-area-div {
        width: 50%;
        float: left;
        min-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }

    img.index-workflow-img {
        width: 100%;
        padding-right: 100px;
        box-sizing: border-box;
    }

    div.index-workflow-txt-area-div {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    h2.page-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3em;
        font-weight: bold;
    }

    ul.index-workflow-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.index-workflow-item-li {
        width: 100%;
        min-height: 70px;
        position: relative;
        padding-left: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;

    }

    div.index-workflow-step-div {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
    }

    div.index-workflow-inner-step-div {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: relative;
        border: 3px solid black;
        transition: 500ms;
        transition-property: border;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li.index-workflow-item-li.active div.index-workflow-inner-step-div {
        border: 3px solid #04826f;
        transition: 500ms;
        transition-property: border;
    }

    .index-workflow-icon {
        width: 100%;
        text-align: center;
        color: black;
        font-size: 40px;
        bottom: 5px;
        transition: 500ms;
        transition-property: color;
    }

    li.index-workflow-item-li.active span.index-workflow-step-icon-span {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    div.index-workflow-txt-div {
        width: 100%;
        padding-top: 10px;
        min-height: 60px;
        overflow: hidden;
    }

    div.index-workflow-txt-div:after {
        top: 4.5rem;
        bottom: 0;
        left: calc(2rem - 0.30rem);
        width: .25rem;
        background-color: #b4b4b4;
        content: '';
        position: absolute;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li.active div.index-workflow-txt-div:after {
        background-color: #04826f;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li:last-child div.index-workflow-txt-div:after {
        display: none;
    }

    h3.index-workflow-step-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1em;
        line-height: 1;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        transition: 500ms;
        transition-property: color;
        font-weight: bold;
    }

    li.index-workflow-item-li.active h3.index-workflow-step-title-h3 {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    p.index-workflow-step-txt-p {
        font-family: 'SF Pro Display';
        font-size: 0.8em;
        color: black;
        margin: 0;
        padding: 0;
    }

    section.index-cta-section {
        background-color: black;
        padding: 50px 20px;
    }

    h1.cta {
        color: white;
        line-height: 1;
        font-size: 1.5em;
    }

    p.index-cta-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    div.index-cta-hoewewerken-area {
        width: 100%;
        min-height: 100px;
        position: relative;
        margin-top: 50px;
        float: left;
    }

    div.index-cta-hoewewerken-item-cards {
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        float: left;
        width: 100%;
        margin-bottom: 20px;
    }

    div.index-cta-hoewewerken-item-cards:first-child {
        margin-left: 0;
    }

    div.index-cta-hoewewerken-top-area {
        width: 100%;
        height: 50px;
    }

    span.index-cta-hoewewerken-step-span {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: #017101;
        margin: 0;
        padding: 0;
        font-weight: bold;
        padding: 5px 20px;
        display: inline-block;
        background-color: #b2f7b6;
        border-radius: 50px;
    }

    div.index-cta-hoewewerken-content-area {
        width: 100%;
        min-height: 100px;
    }

    h4.index-cta-hoewewerken-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
        line-height: 1;
    }

    p.index-cta-hoewewerken-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;

    }

    section.index-aboutus-section {
        width: 100%;
        min-height: 100px;
        padding: 0 20px;
        margin: 50px 0;
        overflow: hidden;
    }

    div.index-cta-buttons-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.index-cta-button {
        font-family: 'SF Pro Display';
        font-size: 1.3em;
        color: black;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        border-radius: 50px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;

    }

    button.index-cta-button.improve {
        color: white;
        border: 3px solid white;
    }

    button.index-cta-button.improve:hover {
        background-color: white;
        color: black;
        transition: 200ms;
        transition-property: background-color;
        order: 1px solid black;
    }

    button.index-cta-button.scratch {
        background-color: white;
        color: black;
    }

    button.index-cta-button.scratch:hover {
        background-color: rgb(226, 226, 226);
        transition: 200ms;
        transition-property: background-color;
    }

    button.index-cta-button:last-child {
        margin-left: 20px;
    }

    div.index-overons-img-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    img.index-overons-img {
        width: 100%;
        height: 400px;
        border-radius: 20px;
        margin-bottom: 50px;
        object-fit: cover;
        object-position: center;
    }

    div.index-overons-txt-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: inherit;
    }

    div.index-overons-inner-txt-div {
        width: 100%;
        min-height: 100px;
    }

    p.index-overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }

    button.index-overons-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-overons-btn:hover {
        background-color: white;
        transition: 200ms;
        transition-property: background-color;
        color: black;
        border-color: black;
        border: 1px solid black;
    }

    h1.overons {
        text-align: left;
    }

    section.index-partners-section {
        width: 100%;
        min-height: 100px;
        padding: 25px 0px;
    }

    div.index-partner-inner-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
        margin-top: 30px;
    }

    div.index-partner-item-div {
        height: 20px;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.index-partner-item-img {
        width: auto;
        height: 100%;
        filter: grayscale(100%);
    }

    img.index-partner-item-img:hover {
        filter: grayscale(0%);
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.index-hero-area-div {
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: left center;
        align-items: center;
        padding-left: 15%;
        padding-right: 15%;
    }

    .background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        z-index: -1; 
        filter: brightness(0.75) grayscale(0.5);
      }

    div.index-hero-inner-div {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    div.index-hero-text-div {
        color: white;
    }

    h1.index-hero-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 3em;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
        min-height: 50px;
    }

    p.index-hero-subtitle-p {
        font-family: 'SF Pro Display';
        font-size: 1.4em;
        font-weight: bold;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    p.index-hero-small-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.4em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        line-height: 1;
    }

    button.index-hero-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        background-color: white;
        border-radius: 10px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-hero-btn:hover {
        background-color: #e6e6e6;
        transition: 200ms;
        transition-property: background-color;
    }

    div.index-hero-quick-info-area-div {
        min-height: 50px;
        margin-top: 50px;
    }

    div.index-hero-quick-info-item-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 20px;
    }

    p.index-hero-quick-info-value-txt-p {
        font-family: 'SF Pro Display';
        font-size: 2.5em;
        color: white;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;

    }

    /* span.index-hero-quick-info-value-span {
        color: rgb(3, 127, 115);
    } */

    p.index-hero-quick-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: white;
        margin: 0;
        padding: 0;
        font-weight: bold;

    }

    section.index-portfolio-section {
        width: 100%;
        padding: 50px 20px;
        overflow: hidden;
    }

    h1.index-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }

    ul.index-portfolio-listing-ul {
        width: 100%;
        min-height: 100px;
        list-style-type: none;

    }

    section.index-services-section {
        width: 100%;
    }

    div.index-services-inner-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        width: 90%;
        margin: 0 auto;
        margin-top: 50px;
    }

    div.index-services-item-div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        transition: 200ms;
        transition-property: box-shadow;
        padding: 50px;
    }

    .index-services-item-icon {
        font-size: 100px;
        color: black;
    }

    h2.index-services-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
    }

    p.index-services-item-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    section.index-workflow-section {
        width: 100%;
        min-height: 100vh;
        padding: 20px;
        overflow: hidden;
        box-sizing: border-box;
        margin: 50px 0;
    }

    div.index-workflow-img-area-div {
        width: 50%;
        float: left;
        min-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }

    img.index-workflow-img {
        width: 100%;
        padding-right: 100px;
        box-sizing: border-box;
    }

    div.index-workflow-txt-area-div {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    h2.page-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2em;
        font-weight: bold;
    }

    ul.index-workflow-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.index-workflow-item-li {
        width: 100%;
        min-height: 70px;
        position: relative;
        padding-left: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;

    }

    div.index-workflow-step-div {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
    }

    div.index-workflow-inner-step-div {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: relative;
        border: 3px solid black;
        transition: 500ms;
        transition-property: border;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li.index-workflow-item-li.active div.index-workflow-inner-step-div {
        border: 3px solid #04826f;
        transition: 500ms;
        transition-property: border;
    }

    .index-workflow-icon {
        width: 100%;
        text-align: center;
        color: black;
        font-size: 40px;
        bottom: 5px;
        transition: 500ms;
        transition-property: color;
    }

    li.index-workflow-item-li.active span.index-workflow-step-icon-span {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    div.index-workflow-txt-div {
        width: 100%;
        padding-top: 10px;
        min-height: 60px;
        overflow: hidden;
    }

    div.index-workflow-txt-div:after {
        top: 4.5rem;
        bottom: 0;
        left: calc(2rem - 0.30rem);
        width: .25rem;
        background-color: #b4b4b4;
        content: '';
        position: absolute;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li.active div.index-workflow-txt-div:after {
        background-color: #04826f;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li:last-child div.index-workflow-txt-div:after {
        display: none;
    }

    h3.index-workflow-step-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        line-height: 1;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        transition: 500ms;
        transition-property: color;
        font-weight: bold;
    }

    li.index-workflow-item-li.active h3.index-workflow-step-title-h3 {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    p.index-workflow-step-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;
    }

    section.index-cta-section {
        background-color: black;
        padding: 50px 20px;
    }

    h1.cta {
        color: white;
        line-height: 1;
        font-size: 2em;
    }

    p.index-cta-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    div.index-cta-hoewewerken-area {
        width: 100%;
        min-height: 100px;
        position: relative;
        margin-top: 50px;
        float: left;
    }

    div.index-cta-hoewewerken-item-cards {
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        float: left;
        width: 100%;
        margin-bottom: 20px;
    }

    div.index-cta-hoewewerken-item-cards:first-child {
        margin-left: 0;
    }

    div.index-cta-hoewewerken-top-area {
        width: 100%;
        height: 50px;
    }

    span.index-cta-hoewewerken-step-span {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: #017101;
        margin: 0;
        padding: 0;
        font-weight: bold;
        padding: 5px 20px;
        display: inline-block;
        background-color: #b2f7b6;
        border-radius: 50px;
    }

    div.index-cta-hoewewerken-content-area {
        width: 100%;
        min-height: 100px;
    }

    h4.index-cta-hoewewerken-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
        line-height: 1;
    }

    p.index-cta-hoewewerken-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;

    }

    section.index-aboutus-section {
        width: 100%;
        min-height: 100px;
        padding: 0 20px;
        margin: 50px 0;
        overflow: hidden;
    }

    div.index-cta-buttons-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.index-cta-button {
        font-family: 'SF Pro Display';
        font-size: 1.3em;
        color: black;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        border-radius: 50px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;

    }

    button.index-cta-button.improve {
        color: white;
        border: 3px solid white;
    }

    button.index-cta-button.improve:hover {
        background-color: white;
        color: black;
        transition: 200ms;
        transition-property: background-color;
        order: 1px solid black;
    }

    button.index-cta-button.scratch {
        background-color: white;
        color: black;
    }

    button.index-cta-button.scratch:hover {
        background-color: rgb(226, 226, 226);
        transition: 200ms;
        transition-property: background-color;
    }

    button.index-cta-button:last-child {
        margin-left: 20px;
    }

    div.index-overons-img-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    img.index-overons-img {
        width: 100%;
        height: 400px;
        border-radius: 20px;
        margin-bottom: 50px;
        object-fit: cover;
        object-position: center;
    }

    div.index-overons-txt-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: inherit;
    }

    div.index-overons-inner-txt-div {
        width: 100%;
        min-height: 100px;
    }

    p.index-overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }

    button.index-overons-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-overons-btn:hover {
        background-color: white;
        transition: 200ms;
        transition-property: background-color;
        color: black;
        border-color: black;
        border: 1px solid black;
    }

    h1.overons {
        text-align: left;
    }

    section.index-partners-section {
        width: 100%;
        min-height: 100px;
        padding: 25px 0px;
    }

    div.index-partner-inner-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    div.index-partner-item-div {
        height: 30px;
        float: left;
        margin-left: 50px;
    }

    img.index-partner-item-img {
        width: auto;
        height: 100%;
        filter: grayscale(100%);
    }

    img.index-partner-item-img:hover {
        filter: grayscale(0%);
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.index-hero-area-div {
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: left center;
        align-items: center;
        padding-left: 15%;
        padding-right: 15%;
        bottom: 0;
    }

    .background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        z-index: -1; 
        filter: brightness(0.75) grayscale(0.5);
      }

    div.index-hero-inner-div {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    div.index-hero-text-div {
        color: white;
    }

    h1.index-hero-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 50px;
        color: white;
        margin: 0;
        padding: 0;
    }

    p.index-hero-subtitle-p {
        font-family: 'SF Pro Display';
        font-size: 30px;
        font-weight: bold;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    p.index-hero-small-txt-p {
        font-family: 'SF Pro Display';
        font-size: 25px;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;

    }

    button.index-hero-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        background-color: white;
        border-radius: 10px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-hero-btn:hover {
        background-color: #e6e6e6;
        transition: 200ms;
        transition-property: background-color;
    }

    div.index-hero-quick-info-area-div {
        min-height: 50px;
        margin-top: 50px;
    }

    div.index-hero-quick-info-item-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 20px;
    }

    p.index-hero-quick-info-value-txt-p {
        font-family: 'SF Pro Display';
        font-size: 60px;
        color: white;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;

    }

    p.index-hero-quick-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: white;
        margin: 0;
        padding: 0;
        font-weight: bold;

    }

    section.index-portfolio-section {
        width: 100%;
        padding: 100px;
    }

    h1.index-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 40px;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    ul.index-portfolio-listing-ul {
        width: 100%;
        min-height: 100px;
        list-style-type: none;

    }

    section.index-services-section {
        width: 100%;
        padding: 100px 0px;
    }

    div.index-services-inner-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        width: 90%;
        margin: 0 auto;
        margin-top: 50px;
    }

    div.index-services-item-div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        transition: 200ms;
        transition-property: box-shadow;
        padding: 50px;
    }

    .index-services-item-icon {
        font-size: 100px;
        color: black;
    }

    h2.index-services-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
    }

    p.index-services-item-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    section.index-workflow-section {
        width: 100%;
        min-height: 100vh;
        padding: 50px 100px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
    }

    div.index-workflow-img-area-div {
        width: 50%;
        float: left;
        min-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.index-workflow-img {
        width: 100%;
        padding-right: 100px;
        box-sizing: border-box;
    }

    div.index-workflow-txt-area-div {
        width: 50%;
        float: left;
        min-height: inherit;
    }

    h2.page-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2em;
        font-weight: bold;
    }

    ul.index-workflow-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.index-workflow-item-li {
        width: 100%;
        min-height: 70px;
        position: relative;
        padding-left: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;

    }

    div.index-workflow-step-div {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
    }

    div.index-workflow-inner-step-div {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: relative;
        border: 3px solid black;
        transition: 500ms;
        transition-property: border;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li.index-workflow-item-li.active div.index-workflow-inner-step-div {
        border: 3px solid #04826f;
        transition: 500ms;
        transition-property: border;
    }

    .index-workflow-icon {
        width: 100%;
        text-align: center;
        color: black;
        font-size: 40px;
        bottom: 5px;
        transition: 500ms;
        transition-property: color;
    }

    li.index-workflow-item-li.active span.index-workflow-step-icon-span {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    div.index-workflow-txt-div {
        width: 100%;
        padding-top: 10px;
        min-height: 60px;
        overflow: hidden;
    }

    div.index-workflow-txt-div:after {
        top: 4.5rem;
        bottom: 0;
        left: calc(2rem - 0.30rem);
        width: .25rem;
        background-color: #b4b4b4;
        content: '';
        position: absolute;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li.active div.index-workflow-txt-div:after {
        background-color: #04826f;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li:last-child div.index-workflow-txt-div:after {
        display: none;
    }

    h3.index-workflow-step-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1.6em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        transition: 500ms;
        transition-property: color;
        font-weight: bold;
    }

    li.index-workflow-item-li.active h3.index-workflow-step-title-h3 {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    p.index-workflow-step-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
    }

    section.index-cta-section {
        background-color: black;
        padding: 100px;
    }

    h1.cta {
        color: white;
    }

    p.index-cta-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
    }

    div.index-cta-hoewewerken-area {
        width: 100%;
        min-height: 100px;
        position: relative;
        margin-top: 50px;
        float: left;
    }

    div.index-cta-hoewewerken-item-cards {
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        width: calc((100% / 4) - 20px);
        float: left;
        margin-left: 26.5px;

    }

    div.index-cta-hoewewerken-item-cards:first-child {
        margin-left: 0;
    }

    div.index-cta-hoewewerken-item-cards:nth-child(2) {
        margin-top: 50px;
    }

    div.index-cta-hoewewerken-item-cards:nth-child(3) {
        margin-top: 100px;
    }

    div.index-cta-hoewewerken-item-cards:nth-child(4) {
        margin-top: 150px;
    }

    div.index-cta-hoewewerken-top-area {
        width: 100%;
        height: 50px;
    }

    span.index-cta-hoewewerken-step-span {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: #017101;
        margin: 0;
        padding: 0;
        font-weight: bold;
        padding: 5px 20px;
        display: inline-block;
        background-color: #b2f7b6;
        border-radius: 50px;
    }

    div.index-cta-hoewewerken-content-area {
        width: 100%;
        min-height: 100px;
    }

    h4.index-cta-hoewewerken-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 24px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        font-weight: bold;
        line-height: 1;
    }

    p.index-cta-hoewewerken-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;

    }

    section.index-aboutus-section {
        width: 100%;
        min-height: 100px;
        padding: 100px 0px;
        display: flex;
    }

    div.index-cta-buttons-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.index-cta-button {
        font-family: 'SF Pro Display';
        font-size: 1.3em;
        color: black;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        border-radius: 50px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;

    }

    button.index-cta-button.improve {
        color: white;
        border: 3px solid white;
    }

    button.index-cta-button.improve:hover {
        background-color: white;
        color: black;
        transition: 200ms;
        transition-property: background-color;
        order: 1px solid black;
    }

    button.index-cta-button.scratch {
        background-color: white;
        color: black;
    }

    button.index-cta-button.scratch:hover {
        background-color: rgb(226, 226, 226);
        transition: 200ms;
        transition-property: background-color;
    }

    button.index-cta-button:last-child {
        margin-left: 20px;
    }

    div.index-overons-img-area {
        width: 45%;
        float: left;
        min-height: inherit;
    }

    img.index-overons-img {
        width: 100%;
        height: auto;
        border-top-right-radius: 150px;
        border-bottom-right-radius: 150px;
    }

    div.index-overons-txt-area {
        width: 55%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: inherit;
    }

    div.index-overons-inner-txt-div {
        width: 50%;
        min-height: 100px;
    }

    p.index-overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }

    button.index-overons-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid transparent;
    }

    button.index-overons-btn:hover {
        background-color: white;
        transition: 200ms;
        transition-property: background-color;
        color: black;
        border-color: black;
        border: 1px solid black;
    }

    h1.overons {
        text-align: left;
    }

    section.index-partners-section {
        width: 100%;
        min-height: 100px;
        padding: 25px 0px;
    }

    div.index-partner-inner-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    div.index-partner-item-div {
        height: 30px;
        float: left;
        margin-left: 50px;
    }

    img.index-partner-item-img {
        width: auto;
        height: 100%;
        filter: grayscale(100%);
    }

    img.index-partner-item-img:hover {
        filter: grayscale(0%);
    }

    .review-section {
        width: 100%;
        padding: 20px;
        box-sizing: border-box; /* Zorg ervoor dat padding en border niet de totale breedte beïnvloeden */
    }
    
    .review-inner-div {
        width: 100%;
        margin: 0 auto;
        padding: 0;
    }

    .swiper-wrapper {
        width: 100%;
    }
    
    .review-item-div {
        background-color: #fff;
        border-radius: 10px;
        padding: 40px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 100%; /* Zorg ervoor dat de slide de volledige breedte van de container gebruikt */
        float: left;
    }
    
    .left-quarter {
        width: 25%; 
        background-color: #f2f2f2; 
        padding: 0px; 
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div.review-text-div {
        width: 100%;
    }
    
    .review-text-div p {
        font-size: 16px; 
        line-height: 1.5; 
        color: #333; 
        font-weight: bold;
        font-style: oblique;
        margin: 0px;
        width: 100%;
    }


    h1.home-partners-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        padding: 10px;
    }

    .swiper-slide {
        width: 100%;
    }

}

@media (min-width: 1450px) {
    div.index-hero-area-div {
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: left center;
        align-items: center;
        padding-left: 15%;
        padding-right: 15%;
    }

    .background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        z-index: -1; 
        filter: brightness(0.75) grayscale(0.5);
      }

    div.index-hero-inner-div {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    div.index-hero-text-div {
        color: white;
    }

    h1.index-hero-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 50px;
        color: white;
        margin: 0;
        padding: 0;

    }

    p.index-hero-subtitle-p {
        font-family: 'SF Pro Display';
        font-size: 30px;
        font-weight: bold;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    p.index-hero-small-txt-p {
        font-family: 'SF Pro Display';
        font-size: 25px;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;

    }

    button.index-hero-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        background-color: white;
        border-radius: 10px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
    }

    button.index-hero-btn:hover {
        background-color: #e6e6e6;
        transition: 200ms;
        transition-property: background-color;
    }

    div.index-hero-quick-info-area-div {
        min-height: 50px;
        margin-top: 50px;
    }

    div.index-hero-quick-info-item-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 20px;
    }

    p.index-hero-quick-info-value-txt-p {
        font-family: 'SF Pro Display';
        font-size: 60px;
        color: white;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;

    }

    p.index-hero-quick-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: white;
        margin: 0;
        padding: 0;
        font-weight: bold;

    }

    section.index-portfolio-section {
        width: 100%;
        padding: 100px;
    }

    h1.index-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 40px;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    ul.index-portfolio-listing-ul {
        width: 100%;
        min-height: 100px;
        list-style-type: none;

    }

    section.index-services-section {
        width: 100%;
        padding: 100px 0px;
    }

    div.index-services-inner-div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        width: 90%;
        margin: 0 auto;
        margin-top: 50px;
    }

    div.index-services-item-div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        transition: 200ms;
        transition-property: box-shadow;
        padding: 50px;
    }

    .index-services-item-icon {
        font-size: 100px;
        color: black;
    }

    h2.index-services-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 1.7em;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
    }

    p.index-services-item-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    section.index-workflow-section {
        width: 100%;
        min-height: 100vh;
        padding: 50px 200px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
    }

    div.index-workflow-img-area-div {
        width: 50%;
        float: left;
        min-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.index-workflow-img {
        width: 100%;
        padding-right: 100px;
        box-sizing: border-box;
    }

    div.index-workflow-txt-area-div {
        width: 50%;
        float: left;
        min-height: inherit;
    }

    h2.page-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2em;
        font-weight: bold;
    }

    ul.index-workflow-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.index-workflow-item-li {
        width: 100%;
        min-height: 70px;
        position: relative;
        padding-left: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;

    }

    div.index-workflow-step-div {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
    }

    div.index-workflow-inner-step-div {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: relative;
        border: 3px solid black;
        transition: 500ms;
        transition-property: border;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li.index-workflow-item-li.active div.index-workflow-inner-step-div {
        border: 3px solid #04826f;
        transition: 500ms;
        transition-property: border;
    }

    .index-workflow-icon {
        width: 100%;
        text-align: center;
        color: black;
        font-size: 40px;
        bottom: 5px;
        transition: 500ms;
        transition-property: color;
    }

    li.index-workflow-item-li.active span.index-workflow-step-icon-span {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    div.index-workflow-txt-div {
        width: 100%;
        padding-top: 10px;
        min-height: 60px;
        overflow: hidden;
    }

    div.index-workflow-txt-div:after {
        top: 4.5rem;
        bottom: 0;
        left: calc(2rem - 0.30rem);
        width: .25rem;
        background-color: #b4b4b4;
        content: '';
        position: absolute;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li.active div.index-workflow-txt-div:after {
        background-color: #04826f;
        transition: 500ms;
        transition-property: background-color;
    }

    li.index-workflow-item-li:last-child div.index-workflow-txt-div:after {
        display: none;
    }

    h3.index-workflow-step-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1.6em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        transition: 500ms;
        transition-property: color;
        font-weight: bold;
    }

    li.index-workflow-item-li.active h3.index-workflow-step-title-h3 {
        color: #04826f;
        transition: 500ms;
        transition-property: color;
    }

    p.index-workflow-step-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
    }

    section.index-cta-section {
        background-color: black;
        padding: 100px 200px;
    }

    h1.cta {
        color: white;
    }

    p.index-cta-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: white;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
    }

    div.index-cta-hoewewerken-area {
        width: 100%;
        min-height: 100px;
        position: relative;
        margin-top: 50px;
        float: left;
    }

    div.index-cta-hoewewerken-item-cards {
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        width: calc((100% / 4) - 20px);
        float: left;
        margin-left: 26.5px;

    }

    div.index-cta-hoewewerken-item-cards:first-child {
        margin-left: 0;
    }

    div.index-cta-hoewewerken-item-cards:nth-child(2) {
        margin-top: 50px;
    }

    div.index-cta-hoewewerken-item-cards:nth-child(3) {
        margin-top: 100px;
    }

    div.index-cta-hoewewerken-item-cards:nth-child(4) {
        margin-top: 150px;
    }

    div.index-cta-hoewewerken-top-area {
        width: 100%;
        height: 50px;
    }

    span.index-cta-hoewewerken-step-span {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: #017101;
        margin: 0;
        padding: 0;
        font-weight: bold;
        padding: 5px 20px;
        display: inline-block;
        background-color: #b2f7b6;
        border-radius: 50px;
    }

    div.index-cta-hoewewerken-content-area {
        width: 100%;
        min-height: 100px;
    }

    h4.index-cta-hoewewerken-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 24px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        font-weight: bold;
        line-height: 1;
    }

    p.index-cta-hoewewerken-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;

    }

    section.index-aboutus-section {
        width: 100%;
        min-height: 100px;
        padding: 100px 0px;
        display: flex;
    }

    div.index-cta-buttons-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.index-cta-button {
        font-family: 'SF Pro Display';
        font-size: 1.3em;
        color: black;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.2;
        border-radius: 50px;
        padding: 10px 20px;
        margin-top: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;

    }

    button.index-cta-button.improve {
        color: white;
        border: 3px solid white;
    }

    button.index-cta-button.improve:hover {
        background-color: white;
        color: black;
        transition: 200ms;
        transition-property: background-color;
        order: 1px solid black;
    }

    button.index-cta-button.scratch {
        background-color: white;
        color: black;
    }

    button.index-cta-button.scratch:hover {
        background-color: rgb(226, 226, 226);
        transition: 200ms;
        transition-property: background-color;
    }

    button.index-cta-button:last-child {
        margin-left: 20px;
    }

    div.index-overons-img-area {
        width: 45%;
        float: left;
        min-height: inherit;
    }

    img.index-overons-img {
        width: 100%;
        height: auto;
        border-top-right-radius: 150px;
        border-bottom-right-radius: 150px;
    }

    div.index-overons-txt-area {
        width: 55%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: inherit;
    }

    div.index-overons-inner-txt-div {
        width: 50%;
        min-height: 100px;
    }

    p.index-overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }

    button.index-overons-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        transition: 200ms;
        transition-property: background-color;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid transparent;
    }

    button.index-overons-btn:hover {
        background-color: white;
        transition: 200ms;
        transition-property: background-color;
        color: black;
        border-color: black;
        border: 1px solid black;
    }

    h1.overons {
        text-align: left;
    }

    section.index-partners-section {
        width: 100%;
        min-height: 100px;
        padding: 25px 0px;
    }

    div.index-partner-inner-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    div.index-partner-item-div {
        height: 40px;
        float: left;
        margin-left: 50px;
    }

    img.index-partner-item-img {
        width: auto;
        height: 100%;
        filter: grayscale(100%);
    }

    img.index-partner-item-img:hover {
        filter: grayscale(0%);
    }
}