@media (max-width: 575.98px) {
    div.workflow-item p.workflow-content {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }   
    
    section.workflow-section {
        padding: 0 5%;
        margin-top: 50px;
    }
    
    div.workflow-list {
        width: 100%;
        margin-top: 50px;
    }
    
    div.workflow-item {
        width: 100%;
        min-height: 100px;
        display: flex;
    }
    
    div.workflow-step-counter {
        width: 200px;
        min-height: inherit;
        flex-grow: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    
    div.workflow-step-counter span {
        font-family: 'SF Pro Display';
        font-size: 4em;
        color: lightgray;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    div.workflow-step-counter:after {
        border-left: 3px dotted lightgray;
        bottom: 0;
        content: "";
        left: 50%;
        position: absolute;
        top: 6rem;
        transition: .5s;
        transition-property: background-color;
    }
    
    div.workflow-item:last-child  div.workflow-step-counter:after{
        border-left: none !important;
        content: none !important;
    }
    
    div.workflow-content {
        width: 100%;
        min-height: inherit;
        flex-grow: 1;
        margin-bottom: 50px;
    }
    
    h3.workflow-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1.3em;
        color: black;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.workflow-price-txt-p {
        font-family: 'SF Pro Display';
        font-size: 0.9em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-decoration: underline;
    }
    
    p.workflow-txt-p {
        font-family: 'SF Pro Display';
        font-size: 0.9em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    div.workflow-box {
        width: 100%;
        min-height: 50px;
        display: flex;
        position: relative;
        border: 1px solid lightgray;
        border-radius: 20px;
        padding: 10px;
        margin: 10px 0;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    
    div.workflow-content > :last-of-type:after {
        border-right: none !important;
        content: "";
    }
    
    h1.workflow-section-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }
    
    h2.workflow-section-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
        font-style:italic;
    }
    
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.workflow-item p.workflow-content {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }   
    
    section.workflow-section {
        padding: 0 5%;
        margin-top: 50px;
    }
    
    div.workflow-list {
        width: 100%;
        margin-top: 50px;
    }
    
    div.workflow-item {
        width: 100%;
        min-height: 100px;
        display: flex;
    }
    
    div.workflow-step-counter {
        width: 200px;
        min-height: inherit;
        flex-grow: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    
    div.workflow-step-counter span {
        font-family: 'SF Pro Display';
        font-size: 5em;
        color: lightgray;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    div.workflow-step-counter:after {
        border-left: 3px dotted lightgray;
        bottom: 0;
        content: "";
        left: 50%;
        position: absolute;
        top: 8rem;
        transition: .5s;
        transition-property: background-color;
    }
    
    div.workflow-item:last-child  div.workflow-step-counter:after{
        border-left: none !important;
        content: none !important;
    }
    
    div.workflow-content {
        width: 100%;
        min-height: inherit;
        flex-grow: 1;
        margin-bottom: 50px;
    }
    
    h3.workflow-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.workflow-price-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-decoration: underline;
    }
    
    p.workflow-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    div.workflow-box {
        width: 100%;
        min-height: 50px;
        display: flex;
        position: relative;
        border: 1px solid lightgray;
        border-radius: 20px;
        padding: 10px;
        margin: 10px 0;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    
    div.workflow-content > :last-of-type:after {
        border-right: none !important;
        content: "";
    }
    
    h1.workflow-section-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }
    
    h2.workflow-section-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
        font-style:italic;
    }
    
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.workflow-item p.workflow-content {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }   
    
    section.workflow-section {
        padding: 0 5%;
        margin-top: 50px;
    }
    
    div.workflow-list {
        width: 100%;
        margin-top: 50px;
    }
    
    div.workflow-item {
        width: 100%;
        min-height: 100px;
        display: flex;
    }
    
    div.workflow-step-counter {
        width: 300px;
        min-height: inherit;
        flex-grow: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    
    div.workflow-step-counter span {
        font-family: 'SF Pro Display';
        font-size: 6em;
        color: lightgray;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    div.workflow-step-counter:after {
        border-left: 3px dotted lightgray;
        bottom: 0;
        content: "";
        left: 50%;
        position: absolute;
        top: 8rem;
        transition: .5s;
        transition-property: background-color;
    }
    
    div.workflow-item:last-child  div.workflow-step-counter:after{
        border-left: none !important;
        content: none !important;
    }
    
    div.workflow-content {
        width: 100%;
        min-height: inherit;
        flex-grow: 1;
        margin-bottom: 50px;
    }
    
    h3.workflow-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.workflow-price-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-decoration: underline;
    }
    
    p.workflow-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    div.workflow-box {
        width: 100%;
        min-height: 50px;
        display: flex;
        position: relative;
        border: 1px solid lightgray;
        border-radius: 20px;
        padding: 10px;
        margin: 10px 0;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    
    div.workflow-content > :last-of-type:after {
        border-right: none !important;
        content: "";
    }
    
    h1.workflow-section-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }
    
    h2.workflow-section-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
        font-style:italic;
    }
    
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.workflow-item p.workflow-content {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }   
    
    section.workflow-section {
        padding: 0 5%;
        margin-top: 50px;
    }
    
    div.workflow-list {
        width: 100%;
        margin-top: 50px;
    }
    
    div.workflow-item {
        width: 100%;
        min-height: 100px;
        display: flex;
    }
    
    div.workflow-step-counter {
        width: 300px;
        min-height: inherit;
        flex-grow: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    
    div.workflow-step-counter span {
        font-family: 'SF Pro Display';
        font-size: 6em;
        color: lightgray;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    div.workflow-step-counter:after {
        border-left: 3px dotted lightgray;
        bottom: 0;
        content: "";
        left: 50%;
        position: absolute;
        top: 8rem;
        transition: .5s;
        transition-property: background-color;
    }
    
    div.workflow-item:last-child  div.workflow-step-counter:after{
        border-left: none !important;
        content: none !important;
    }
    
    div.workflow-content {
        width: 100%;
        min-height: inherit;
        flex-grow: 1;
        margin-bottom: 50px;
    }
    
    h3.workflow-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.workflow-price-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-decoration: underline;
    }
    
    p.workflow-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    div.workflow-box {
        width: 100%;
        min-height: 50px;
        display: flex;
        position: relative;
        border: 1px solid lightgray;
        border-radius: 20px;
        padding: 10px;
        margin: 10px 0;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    
    div.workflow-content > :last-of-type:after {
        border-right: none !important;
        content: "";
    }
    
    h1.workflow-section-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }
    
    h2.workflow-section-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
        font-style:italic;
    }
    
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.workflow-item p.workflow-content {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }   
    
    section.workflow-section {
        padding: 0 10%;
    }
    
    div.workflow-list {
        width: 100%;
        margin-top: 50px;
    }
    
    div.workflow-item {
        width: 100%;
        min-height: 100px;
        display: flex;
    }
    
    div.workflow-step-counter {
        width: 300px;
        min-height: inherit;
        flex-grow: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    
    div.workflow-step-counter span {
        font-family: 'SF Pro Display';
        font-size: 6em;
        color: lightgray;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    div.workflow-step-counter:after {
        border-left: 3px dotted lightgray;
        bottom: 0;
        content: "";
        left: 50%;
        position: absolute;
        top: 8rem;
        transition: .5s;
        transition-property: background-color;
    }
    
    div.workflow-item:last-child  div.workflow-step-counter:after{
        border-left: none !important;
        content: none !important;
    }
    
    div.workflow-content {
        width: 100%;
        min-height: inherit;
        flex-grow: 1;
        margin-bottom: 50px;
    }
    
    h3.workflow-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.workflow-price-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-decoration: underline;
    }
    
    p.workflow-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    div.workflow-box {
        width: 100%;
        min-height: 50px;
        display: flex;
        position: relative;
        border: 1px solid lightgray;
        border-radius: 20px;
        padding: 10px;
        margin: 10px 0;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    
    div.workflow-content > :last-of-type:after {
        border-right: none !important;
        content: "";
    }
    
    h1.workflow-section-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }
    
    h2.workflow-section-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
        font-style:italic;
    }
    
}

@media (min-width: 1450px) {
    div.workflow-item p.workflow-content {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }   
    
    section.workflow-section {
        padding: 0 20%;
    }
    
    div.workflow-list {
        width: 100%;
        margin-top: 50px;
    }
    
    div.workflow-item {
        width: 100%;
        min-height: 100px;
        display: flex;
    }
    
    div.workflow-step-counter {
        width: 300px;
        min-height: inherit;
        flex-grow: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    
    div.workflow-step-counter span {
        font-family: 'SF Pro Display';
        font-size: 6em;
        color: lightgray;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    div.workflow-step-counter:after {
        border-left: 3px dotted lightgray;
        bottom: 0;
        content: "";
        left: 50%;
        position: absolute;
        top: 8rem;
        transition: .5s;
        transition-property: background-color;
    }
    
    div.workflow-item:last-child  div.workflow-step-counter:after{
        border-left: none !important;
        content: none !important;
    }
    
    div.workflow-content {
        width: 100%;
        min-height: inherit;
        flex-grow: 1;
        margin-bottom: 50px;
    }
    
    h3.workflow-title-h3 {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.workflow-price-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-decoration: underline;
    }
    
    p.workflow-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    div.workflow-box {
        width: 100%;
        min-height: 50px;
        display: flex;
        position: relative;
        border: 1px solid lightgray;
        border-radius: 20px;
        padding: 10px;
        margin: 10px 0;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    
    div.workflow-content > :last-of-type:after {
        border-right: none !important;
        content: "";
    }
    
    h1.workflow-section-title-h1 {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 2em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
    }
    
    h2.workflow-section-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
        font-style:italic;
    }
    
}