@media (max-width: 575.98px) {
    li.portfolio-item-li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-bottom: 50px;
    }
    
    div.portfolio-item-left-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.portfolio-item-inner-div {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }
    
    h2.portfolio-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    p.portfolio-item-description-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    button.portfolio-item-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        order: 1px solid transparent;
    }
    
    button.portfolio-item-btn:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;

    }
    
    div.portfolio-item-right-area {
        width: 100%;
        float: right;
    }
    
    img.portfolio-item-img {
        width: 100%;
        height: auto;
        margin-bottom: 50px;
    }
    
    img.locus-mockup-img {
        height: auto;
        width: 60%;
        margin: 0 auto;
        margin-bottom: 50px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    li.portfolio-item-li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-bottom: 50px;
    }
    
    div.portfolio-item-left-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.portfolio-item-inner-div {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }
    
    h2.portfolio-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    p.portfolio-item-description-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    button.portfolio-item-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        order: 1px solid transparent;
    }
    
    button.portfolio-item-btn:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;

    }
    
    div.portfolio-item-right-area {
        width: 100%;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    img.portfolio-item-img {
        width: 100%;
        height: auto;
        margin-bottom: 50px;
    }
    
    img.locus-mockup-img {
        height: 100%;
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    li.portfolio-item-li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-bottom: 50px;
    }
    
    div.portfolio-item-left-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.portfolio-item-inner-div {
        width: 80%;
        min-height: 100px;
        margin-bottom: 50px;
    }
    
    h2.portfolio-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    p.portfolio-item-description-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    button.portfolio-item-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        order: 1px solid transparent;
    }
    
    button.portfolio-item-btn:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;

    }
    
    div.portfolio-item-right-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    img.portfolio-item-img {
        width: 100%;
        height: auto;
        max-width: 400px;
    }
    
    img.locus-mockup-img {
        height: 100%;
        width: auto;
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    li.portfolio-item-li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    div.portfolio-item-left-area {
        width: 60%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.portfolio-item-inner-div {
        width: 50%;
        min-height: 100px;
    }
    
    h2.portfolio-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    p.portfolio-item-description-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    button.portfolio-item-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        order: 1px solid transparent;
    }
    
    button.portfolio-item-btn:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;

    }
    
    div.portfolio-item-right-area {
        width: 40%;
        float: left;
    }
    
    img.portfolio-item-img {
        width: 100%;
        height: auto;
    }
    
    img.locus-mockup-img {
        height: 100%;
        width: auto;
    }
}

@media (min-width: 1200px) {
    li.portfolio-item-li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    div.portfolio-item-left-area {
        width: 60%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.portfolio-item-inner-div {
        width: 50%;
        min-height: 100px;
    }
    
    h2.portfolio-item-title-h2 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    p.portfolio-item-description-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    button.portfolio-item-btn {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid transparent;
    }
    
    button.portfolio-item-btn:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;

    }
    
    div.portfolio-item-right-area {
        width: 40%;
        float: left;
    }
    
    img.portfolio-item-img {
        width: 100%;
        height: auto;
    }
    
    img.locus-mockup-img {
        height: 100%;
        width: auto;
    }
}