@font-face {
  font-family: 'SF Pro Display';
  src: url('Assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
      url('Assets/fonts/SFProDisplay-Regular.woff') format('woff'),
      url('Assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('Assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
      url('Assets/fonts/SFProDisplay-Bold.woff') format('woff'),
      url('Assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body {
  height: 100dvh;
}