@media (max-width: 575.98px) {
    nav.main-menu-nav {
        width: 100%;
        height: 80px;
        position: absolute;
        top: 0;
        z-index: 2;
    }
    
    nav.main-menu-nav.active {
        /* background-color: black; */
    }
    
    ul.main-menu-listing {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-menu-logo-li {
        height: 100%;
        float: left;
        padding-right: 20px;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-logo-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-menu-logo-link-img {
        height: 40px;
        width: auto;
        margin-top: 20px;
        margin-bottom: 0;
    }
    
    img.main-menu-logo-link-img.normal {
        display: block;
    }
    
    nav.main-menu-nav:not(.active) img.main-menu-logo-link-img.white {
        display: none;
    }
    
    nav.main-menu-nav img.main-menu-logo-link-img.black {
        display: block;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.black {
        display: none;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.white {
        display: block;
    }
    
    li.main-menu-links-li {
        height: 100%;
        float: left;
        display: none;
    }
    
    div.main-menu-links-list {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
        height: 100%;
    }
    
    ul.main-menu-links-submenu-trigger-ul {
        min-width: 0px;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    li.main-menu-link-li {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
    }
    
    p.main-menu-link-txt-p {
        color: #dedede;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        line-height: 80px;
        padding-left: 15px;
        padding-right: 15px;
        transition: 200ms;
        transition-property: color;
    }
    
    p.main-menu-link-txt-p:hover {
        color: white;
        transition: 200ms;
        transition-property: color;
    }
    
    nav.main-menu-nav.dark:not(.active) p.main-menu-link-txt-p {
        color: black;
    }
    
    div.main-menu-drop-div {
        position: absolute;
        width: 100%;
        max-height: 0px;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: transparent;
        padding-top: 80px;
        overflow: hidden;
    }
    
    div.main-menu-drop-div.active {
        max-height: 80vh;
        background-color: black;
        display: block;
    }
    
    .transition-enabled {
        transition: 500ms;
        transition-property: max-height, background-color;
        display: block !important;
    }
    
    div.main-menu-drop-submenu-content-div {
        width: 70%;
        min-height: 80px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
    }
    
    div.main-menu-inner-submenu-div {
        min-height: 100px;
        float: left;
        margin-right: 50px;
    }
    
    h2.main-menu-submenu-title-h2 {
        color: #c2c2c2;
        font-family: 'SF Pro Display';
        font-weight: normal;
        font-size: 14px;
        margin: 0;
        margin-bottom: 20px;
    }
    
    ul.main-menu-submenu-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-submenu-item-li {
        width: 100%;
        min-height: 0px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        box-sizing: border-box;
    }
    
    p.main-menu-big-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    a.main-menu-submenu-item-a {
        text-decoration: none;
        color: #c6c6c6;
    }
    
    a.main-menu-submenu-item-a:hover {
        color: white;
    }
    
    p.main-menu-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    li.main-menu-quick-butts-li {
        min-width: 50px;
        height: 100%;
        float: right;
        display: none;
    }
    
    ul.main-menu-quick-butts-listing-ul {
        min-width: inherit;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-quick-butt-li {
        min-width: 50px;
        height: 100%;
        float: left;
        box-sizing: border-box;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-quick-butt-a {
        text-decoration: none;
    }
    
    button.main-menu-quick-butt {
        background-color: white;
        border: none;
        outline: none;
        cursor: pointer;
        height: 45px;
        padding: 0;
        margin: 0;
        margin-top: 0px;
        padding: 0 10px;
        margin-top: 17.5px;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 7.5px;
        font-weight: bold;
    }
    
    button.main-menu-quick-butt:hover {
        background-color: rgb(229, 229, 229);
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black {
        background-color: black;
        color: white;
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black:hover {
        background-color: #333333;
    
    }

    li.main-menu-mobile-menu-li {
        height: 100%;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    nav.main-menu-nav {
        width: 100%;
        height: 80px;
        position: absolute;
        top: 0;
        z-index: 2;
    }
    
    nav.main-menu-nav.active {
        /* background-color: black; */
    }
    
    ul.main-menu-listing {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-menu-logo-li {
        height: 100%;
        float: left;
        padding-right: 20px;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-logo-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-menu-logo-link-img {
        height: 40px;
        width: auto;
        margin-top: 20px;
        margin-bottom: 0;
    }
    
    img.main-menu-logo-link-img.normal {
        display: block;
    }
    
    nav.main-menu-nav:not(.active) img.main-menu-logo-link-img.white {
        display: none;
    }
    
    nav.main-menu-nav img.main-menu-logo-link-img.black {
        display: block;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.black {
        display: none;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.white {
        display: block;
    }
    
    li.main-menu-links-li {
        height: 100%;
        float: left;
        display: none;
    }
    
    div.main-menu-links-list {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
        height: 100%;
    }
    
    ul.main-menu-links-submenu-trigger-ul {
        min-width: 0px;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    li.main-menu-link-li {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
    }
    
    p.main-menu-link-txt-p {
        color: #dedede;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        line-height: 80px;
        padding-left: 15px;
        padding-right: 15px;
        transition: 200ms;
        transition-property: color;
    }
    
    p.main-menu-link-txt-p:hover {
        color: white;
        transition: 200ms;
        transition-property: color;
    }
    
    nav.main-menu-nav.dark:not(.active) p.main-menu-link-txt-p {
        color: black;
    }
    
    div.main-menu-drop-div {
        position: absolute;
        width: 100%;
        max-height: 0px;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: transparent;
        padding-top: 80px;
        overflow: hidden;
    }
    
    div.main-menu-drop-div.active {
        max-height: 80vh;
        background-color: black;
        display: block;
    }
    
    .transition-enabled {
        transition: 500ms;
        transition-property: max-height, background-color;
        display: block !important;
    }
    
    div.main-menu-drop-submenu-content-div {
        width: 70%;
        min-height: 80px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
    }
    
    div.main-menu-inner-submenu-div {
        min-height: 100px;
        float: left;
        margin-right: 50px;
    }
    
    h2.main-menu-submenu-title-h2 {
        color: #c2c2c2;
        font-family: 'SF Pro Display';
        font-weight: normal;
        font-size: 14px;
        margin: 0;
        margin-bottom: 20px;
    }
    
    ul.main-menu-submenu-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-submenu-item-li {
        width: 100%;
        min-height: 0px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        box-sizing: border-box;
    }
    
    p.main-menu-big-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    a.main-menu-submenu-item-a {
        text-decoration: none;
        color: #c6c6c6;
    }
    
    a.main-menu-submenu-item-a:hover {
        color: white;
    }
    
    p.main-menu-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    li.main-menu-quick-butts-li {
        min-width: 50px;
        height: 100%;
        float: right;
        display: none;
    }
    
    ul.main-menu-quick-butts-listing-ul {
        min-width: inherit;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-quick-butt-li {
        min-width: 50px;
        height: 100%;
        float: left;
        box-sizing: border-box;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-quick-butt-a {
        text-decoration: none;
    }
    
    button.main-menu-quick-butt {
        background-color: white;
        border: none;
        outline: none;
        cursor: pointer;
        height: 45px;
        padding: 0;
        margin: 0;
        margin-top: 0px;
        padding: 0 10px;
        margin-top: 17.5px;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 7.5px;
        font-weight: bold;
    }
    
    button.main-menu-quick-butt:hover {
        background-color: rgb(229, 229, 229);
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black {
        background-color: black;
        color: white;
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black:hover {
        background-color: #333333;
    
    }

    li.main-menu-mobile-menu-li {
        height: 100%;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    nav.main-menu-nav {
        width: 100%;
        height: 80px;
        position: absolute;
        top: 0;
        z-index: 2;
    }
    
    nav.main-menu-nav.active {
        /* background-color: black; */
    }
    
    ul.main-menu-listing {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-menu-logo-li {
        height: 100%;
        float: left;
        padding-right: 20px;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-logo-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-menu-logo-link-img {
        height: 40px;
        width: auto;
        margin-top: 20px;
        margin-bottom: 0;
    }
    
    img.main-menu-logo-link-img.normal {
        display: block;
    }
    
    nav.main-menu-nav:not(.active) img.main-menu-logo-link-img.white {
        display: none;
    }
    
    nav.main-menu-nav img.main-menu-logo-link-img.black {
        display: block;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.black {
        display: none;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.white {
        display: block;
    }
    
    li.main-menu-links-li {
        height: 100%;
        float: left;
    }
    
    div.main-menu-links-list {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
        height: 100%;
    }
    
    ul.main-menu-links-submenu-trigger-ul {
        min-width: 0px;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    li.main-menu-link-li {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
    }
    
    p.main-menu-link-txt-p {
        color: #dedede;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        line-height: 80px;
        padding-left: 15px;
        padding-right: 15px;
        transition: 200ms;
        transition-property: color;
    }
    
    p.main-menu-link-txt-p:hover {
        color: white;
        transition: 200ms;
        transition-property: color;
    }
    
    nav.main-menu-nav.dark:not(.active) p.main-menu-link-txt-p {
        color: black;
    }
    
    div.main-menu-drop-div {
        position: absolute;
        width: 100%;
        max-height: 0px;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: transparent;
        padding-top: 80px;
        overflow: hidden;
    }
    
    div.main-menu-drop-div.active {
        max-height: 80vh;
        background-color: black;
        display: block;
    }
    
    .transition-enabled {
        transition: 500ms;
        transition-property: max-height, background-color;
        display: block !important;
    }
    
    div.main-menu-drop-submenu-content-div {
        width: 70%;
        min-height: 80px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
    }
    
    div.main-menu-inner-submenu-div {
        min-height: 100px;
        float: left;
        margin-right: 50px;
    }
    
    h2.main-menu-submenu-title-h2 {
        color: #c2c2c2;
        font-family: 'SF Pro Display';
        font-weight: normal;
        font-size: 14px;
        margin: 0;
        margin-bottom: 20px;
    }
    
    ul.main-menu-submenu-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-submenu-item-li {
        width: 100%;
        min-height: 0px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        box-sizing: border-box;
    }
    
    p.main-menu-big-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    a.main-menu-submenu-item-a {
        text-decoration: none;
        color: #c6c6c6;
    }
    
    a.main-menu-submenu-item-a:hover {
        color: white;
    }
    
    p.main-menu-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    li.main-menu-quick-butts-li {
        min-width: 50px;
        height: 100%;
        float: right;
    }
    
    ul.main-menu-quick-butts-listing-ul {
        min-width: inherit;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-quick-butt-li {
        min-width: 50px;
        height: 100%;
        float: left;
        box-sizing: border-box;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-quick-butt-a {
        text-decoration: none;
    }
    
    button.main-menu-quick-butt {
        background-color: white;
        border: none;
        outline: none;
        cursor: pointer;
        height: 45px;
        padding: 0;
        margin: 0;
        margin-top: 0px;
        padding: 0 10px;
        margin-top: 17.5px;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 7.5px;
        font-weight: bold;
    }
    
    button.main-menu-quick-butt:hover {
        background-color: rgb(229, 229, 229);
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black {
        background-color: black;
        color: white;
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black:hover {
        background-color: #333333;
    
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    nav.main-menu-nav {
        width: 100%;
        height: 80px;
        position: absolute;
        top: 0;
        z-index: 2;
    }
    
    nav.main-menu-nav.active {
        /* background-color: black; */
    }
    
    ul.main-menu-listing {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-menu-logo-li {
        height: 100%;
        float: left;
        padding-right: 20px;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-logo-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-menu-logo-link-img {
        height: 40px;
        width: auto;
        margin-top: 20px;
        margin-bottom: 0;
    }
    
    img.main-menu-logo-link-img.normal {
        display: block;
    }
    
    nav.main-menu-nav:not(.active) img.main-menu-logo-link-img.white {
        display: none;
    }
    
    nav.main-menu-nav img.main-menu-logo-link-img.black {
        display: block;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.black {
        display: none;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.white {
        display: block;
    }
    
    li.main-menu-links-li {
        height: 100%;
        float: left;
    }
    
    div.main-menu-links-list {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
        height: 100%;
    }
    
    ul.main-menu-links-submenu-trigger-ul {
        min-width: 0px;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    li.main-menu-link-li {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
    }
    
    p.main-menu-link-txt-p {
        color: #dedede;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        line-height: 80px;
        padding-left: 15px;
        padding-right: 15px;
        transition: 200ms;
        transition-property: color;
    }
    
    p.main-menu-link-txt-p:hover {
        color: white;
        transition: 200ms;
        transition-property: color;
    }
    
    nav.main-menu-nav.dark:not(.active) p.main-menu-link-txt-p {
        color: black;
    }
    
    div.main-menu-drop-div {
        position: absolute;
        width: 100%;
        max-height: 0px;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: transparent;
        padding-top: 80px;
        overflow: hidden;
    }
    
    div.main-menu-drop-div.active {
        max-height: 80vh;
        background-color: black;
        display: block;
    }
    
    .transition-enabled {
        transition: 500ms;
        transition-property: max-height, background-color;
        display: block !important;
    }
    
    div.main-menu-drop-submenu-content-div {
        width: 70%;
        min-height: 80px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
    }
    
    div.main-menu-inner-submenu-div {
        min-height: 100px;
        float: left;
        margin-right: 50px;
    }
    
    h2.main-menu-submenu-title-h2 {
        color: #c2c2c2;
        font-family: 'SF Pro Display';
        font-weight: normal;
        font-size: 14px;
        margin: 0;
        margin-bottom: 20px;
    }
    
    ul.main-menu-submenu-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-submenu-item-li {
        width: 100%;
        min-height: 0px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        box-sizing: border-box;
    }
    
    p.main-menu-big-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    a.main-menu-submenu-item-a {
        text-decoration: none;
        color: #c6c6c6;
    }
    
    a.main-menu-submenu-item-a:hover {
        color: white;
    }
    
    p.main-menu-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    li.main-menu-quick-butts-li {
        min-width: 50px;
        height: 100%;
        float: right;
    }
    
    ul.main-menu-quick-butts-listing-ul {
        min-width: inherit;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-quick-butt-li {
        min-width: 50px;
        height: 100%;
        float: left;
        box-sizing: border-box;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-quick-butt-a {
        text-decoration: none;
    }
    
    button.main-menu-quick-butt {
        background-color: white;
        border: none;
        outline: none;
        cursor: pointer;
        height: 45px;
        padding: 0;
        margin: 0;
        margin-top: 0px;
        padding: 0 10px;
        margin-top: 17.5px;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 7.5px;
        font-weight: bold;
    }
    
    button.main-menu-quick-butt:hover {
        background-color: rgb(229, 229, 229);
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black {
        background-color: black;
        color: white;
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black:hover {
        background-color: #333333;
    
    }
}

@media (min-width: 1200px) {
    nav.main-menu-nav {
        width: 100%;
        height: 80px;
        position: absolute;
        top: 0;
        z-index: 2;
    }
    
    nav.main-menu-nav.active {
        /* background-color: black; */
    }
    
    ul.main-menu-listing {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
    }
    
    li.main-menu-logo-li {
        height: 100%;
        float: left;
        padding-right: 20px;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-logo-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    img.main-menu-logo-link-img {
        height: 40px;
        width: auto;
        margin-top: 20px;
        margin-bottom: 0;
    }
    
    img.main-menu-logo-link-img.normal {
        display: block;
    }
    
    nav.main-menu-nav:not(.active) img.main-menu-logo-link-img.white {
        display: none;
    }
    
    nav.main-menu-nav img.main-menu-logo-link-img.black {
        display: block;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.black {
        display: none;
    }
    
    nav.main-menu-nav.active img.main-menu-logo-link-img.white {
        display: block;
    }
    
    li.main-menu-links-li {
        height: 100%;
        float: left;
    }
    
    div.main-menu-links-list {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
        height: 100%;
    }
    
    ul.main-menu-links-submenu-trigger-ul {
        min-width: 0px;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-link-a {
        text-decoration: none;
        cursor: pointer;
    }
    
    li.main-menu-link-li {
        min-width: 50px;
        float: left;
        box-sizing: border-box;
    }
    
    p.main-menu-link-txt-p {
        color: #dedede;
        font-family: 'SF Pro Display';
        font-size: 14px;
        margin: 0;
        padding: 0;
        line-height: 80px;
        padding-left: 15px;
        padding-right: 15px;
        transition: 200ms;
        transition-property: color;
    }
    
    p.main-menu-link-txt-p:hover {
        color: white;
        transition: 200ms;
        transition-property: color;
    }
    
    nav.main-menu-nav.dark:not(.active) p.main-menu-link-txt-p {
        color: black;
    }
    
    div.main-menu-drop-div {
        position: absolute;
        width: 100%;
        max-height: 0px;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: transparent;
        padding-top: 80px;
        overflow: hidden;
    }
    
    div.main-menu-drop-div.active {
        max-height: 80vh;
        background-color: black;
        display: block;
    }
    
    .transition-enabled {
        transition: 500ms;
        transition-property: max-height, background-color;
        display: block !important;
    }
    
    div.main-menu-drop-submenu-content-div {
        width: 70%;
        min-height: 80px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
    }
    
    div.main-menu-inner-submenu-div {
        min-height: 100px;
        float: left;
        margin-right: 50px;
    }
    
    h2.main-menu-submenu-title-h2 {
        color: #c2c2c2;
        font-family: 'SF Pro Display';
        font-weight: normal;
        font-size: 14px;
        margin: 0;
        margin-bottom: 20px;
    }
    
    ul.main-menu-submenu-listing-ul {
        width: 100%;
        min-height: inherit;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-submenu-item-li {
        width: 100%;
        min-height: 0px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        box-sizing: border-box;
    }
    
    p.main-menu-big-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    a.main-menu-submenu-item-a {
        text-decoration: none;
        color: #c6c6c6;
    }
    
    a.main-menu-submenu-item-a:hover {
        color: white;
    }
    
    p.main-menu-submenu-item-p {
        font-family: 'SF Pro Display';
        font-weight: bold;
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
    
    li.main-menu-quick-butts-li {
        min-width: 50px;
        height: 100%;
        float: right;
    }
    
    ul.main-menu-quick-butts-listing-ul {
        min-width: inherit;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    li.main-menu-quick-butt-li {
        min-width: 50px;
        height: 100%;
        float: left;
        box-sizing: border-box;
        position: relative;
        z-index: 10;
    }
    
    a.main-menu-quick-butt-a {
        text-decoration: none;
    }
    
    button.main-menu-quick-butt {
        background-color: white;
        border: none;
        outline: none;
        cursor: pointer;
        height: 45px;
        padding: 0;
        margin: 0;
        margin-top: 0px;
        padding: 0 10px;
        margin-top: 17.5px;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 7.5px;
        font-weight: bold;
    }
    
    button.main-menu-quick-butt:hover {
        background-color: rgb(229, 229, 229);
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black {
        background-color: black;
        color: white;
    }
    
    nav.main-menu-nav:not(.active) button.main-menu-quick-butt.black:hover {
        background-color: #333333;
    
    }
}

.main-menu-link {
    text-decoration: none;
}