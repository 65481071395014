@media (max-width: 575.98px) {
    div.overons-hero-area {
        min-height: 200px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 100px;
    }

    h1.main-overons-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }

    h1.main-overons-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 1;
    }

    p.overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        line-height: 1.2;
    }

    div.overons-team-area {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 15%;
        padding-right: 15%;
    }

    div.overons-team-item-div {
        /* height: 400px; */
    }

    img.overons-team-person-img {
        width: 100%;
        object-fit: cover;
        border-radius: 400px;
        aspect-ratio: 1 / 1;
        /* defining the aspect ratio of the image */
        object-fit: cover;
    }

    p.overons-team-persoon-naam-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
    }

    p.overons-team-persoon-functie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    ul.overons-team-persoon-socials-listing-ul {
        width: 100%;
        height: 30px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    li.overons-team-persoon-social-item-li {
        width: 30px;
        height: 100%;
        float: left;
    }

    a.overons-team-persoon-social-item-a {
        cursor: pointer;
    }

    img.overons-team-persoon-social-item-img {
        width: 100%;
        height: 100%;
    }

    div.overons-text-area {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    div.overons-text-bar-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-bottom: 20px;
    }

    div.overons-text-bar-title-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    h5.overons-text-bar-title-subtitle-h5 {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    h1.overons-text-bar-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 40px;
        color: black;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    div.overons-text-bar-text-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    p.overons-text-bar-text-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.overons-hero-area {
        min-height: 200px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 100px;
    }

    h1.main-overons-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }

    h1.main-overons-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 1.5em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 1;
    }

    p.overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        line-height: 1.2;
    }

    div.overons-team-area {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 15%;
        padding-right: 15%;
    }

    div.overons-team-item-div {
        /* height: 400px; */
        max-width: 300px;
        margin: 0 auto;
    }

    img.overons-team-person-img {
        width: 100%;
        object-fit: cover;
        border-radius: 400px;
        aspect-ratio: 1 / 1;
        /* defining the aspect ratio of the image */
        object-fit: cover;
    }

    p.overons-team-persoon-naam-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
    }

    p.overons-team-persoon-functie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    ul.overons-team-persoon-socials-listing-ul {
        width: 100%;
        height: 30px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    li.overons-team-persoon-social-item-li {
        width: 30px;
        height: 100%;
        float: left;
    }

    a.overons-team-persoon-social-item-a {
        cursor: pointer;
    }

    img.overons-team-persoon-social-item-img {
        width: 100%;
        height: 100%;
    }

    div.overons-text-area {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    div.overons-text-bar-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-bottom: 20px;
    }

    div.overons-text-bar-title-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    h5.overons-text-bar-title-subtitle-h5 {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    h1.overons-text-bar-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 40px;
        color: black;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    div.overons-text-bar-text-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    p.overons-text-bar-text-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.overons-hero-area {
        min-height: 200px;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 100px;
    }

    h1.main-overons-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 3em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }

    h1.main-overons-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 37px;
    }

    p.overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    div.overons-team-area {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px;
        padding-top: 50px;
        padding-left: 15%;
        padding-right: 15%;
    }

    div.overons-team-item-div {
        /* height: 400px; */
    }

    img.overons-team-person-img {
        width: 100%;
        object-fit: cover;
        border-radius: 400px;
        aspect-ratio: 1 / 1;
        /* defining the aspect ratio of the image */
        object-fit: cover;
    }

    p.overons-team-persoon-naam-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
    }

    p.overons-team-persoon-functie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    ul.overons-team-persoon-socials-listing-ul {
        width: 100%;
        height: 30px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    li.overons-team-persoon-social-item-li {
        width: 30px;
        height: 100%;
        float: left;
    }

    a.overons-team-persoon-social-item-a {
        cursor: pointer;
    }

    img.overons-team-persoon-social-item-img {
        width: 100%;
        height: 100%;
    }

    div.overons-text-area {
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    div.overons-text-bar-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-bottom: 50px;
    }

    div.overons-text-bar-title-area {
        width: 30%;
        min-height: inherit;
        float: left;
    }

    h5.overons-text-bar-title-subtitle-h5 {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: .7b7b7b;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    h1.overons-text-bar-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 40px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
    }

    div.overons-text-bar-text-area {
        width: 70%;
        min-height: inherit;
        float: left;
        ;
    }

    p.overons-text-bar-text-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.overons-hero-area {
        min-height: 200px;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 100px;
    }

    h1.main-overons-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 3em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }

    h1.main-overons-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 37px;
    }

    p.overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    div.overons-team-area {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px;
        padding-top: 50px;
        padding-left: 15%;
        padding-right: 15%;
    }

    div.overons-team-item-div {
        /* height: 400px; */
    }

    img.overons-team-person-img {
        width: 100%;
        object-fit: cover;
        border-radius: 400px;
        aspect-ratio: 1 / 1;
        /* defining the aspect ratio of the image */
        object-fit: cover;
    }

    p.overons-team-persoon-naam-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
    }

    p.overons-team-persoon-functie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    ul.overons-team-persoon-socials-listing-ul {
        width: 100%;
        height: 30px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    li.overons-team-persoon-social-item-li {
        width: 30px;
        height: 100%;
        float: left;
    }

    a.overons-team-persoon-social-item-a {
        cursor: pointer;
    }

    img.overons-team-persoon-social-item-img {
        width: 100%;
        height: 100%;
    }

    div.overons-text-area {
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    div.overons-text-bar-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-bottom: 50px;
    }

    div.overons-text-bar-title-area {
        width: 30%;
        min-height: inherit;
        float: left;
    }

    h5.overons-text-bar-title-subtitle-h5 {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: .7b7b7b;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    h1.overons-text-bar-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 40px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
    }

    div.overons-text-bar-text-area {
        width: 70%;
        min-height: inherit;
        float: left;
        ;
    }

    p.overons-text-bar-text-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media (min-width: 1200px) {
    div.overons-hero-area {
        min-height: 200px;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 100px;
    }

    h1.main-overons-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 3em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }

    h1.main-overons-typwrite-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 30px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        line-height: 37px;
    }

    p.overons-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        width: 60%;
        margin-left: 20%;

    }

    div.overons-team-area {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 50px;
        padding: 40px;
        max-width: 1200px;
        margin: 0 auto;
    }

    div.overons-team-item-div {
        /* height: 400px; */
    }

    img.overons-team-person-img {
        width: 100%;
        object-fit: cover;
        border-radius: 400px;
        aspect-ratio: 1 / 1;
        /* defining the aspect ratio of the image */
        object-fit: cover;
    }

    p.overons-team-persoon-naam-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
    }

    p.overons-team-persoon-functie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        text-align: center;
    }

    ul.overons-team-persoon-socials-listing-ul {
        width: 100%;
        height: 30px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    li.overons-team-persoon-social-item-li {
        width: 30px;
        height: 100%;
        float: left;
    }

    a.overons-team-persoon-social-item-a {
        cursor: pointer;
    }

    img.overons-team-persoon-social-item-img {
        width: 100%;
        height: 100%;
    }

    div.overons-text-area {
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    div.overons-text-bar-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-bottom: 50px;
    }

    div.overons-text-bar-title-area {
        width: 30%;
        min-height: inherit;
        float: left;
    }

    h5.overons-text-bar-title-subtitle-h5 {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: .7b7b7b;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    h1.overons-text-bar-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 40px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
    }

    div.overons-text-bar-text-area {
        width: 70%;
        min-height: inherit;
        float: left;
        ;
    }

    p.overons-text-bar-text-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}