@media (max-width: 575.98px) {
    section.contact-view-section {
        width: 100%;
        min-height: 100vh;
        padding-top: 100px;
        padding-bottom: 50px;
        box-sizing: border-box;
        overflow: hidden;
    }
    
    div#contact-map {
        width: 90%;
        height: 200px;
        background-color: grey;
        float: left;
        overflow: hidden;
        border-radius: 50px;
        margin-left: 5%;
    }
    
    div#contact-info-content-area {
        width: 100%;
        height: 100%;
        float: left;
        padding: 0 5%;
        margin-top: 50px;
    }
    
    h1.contact-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
        line-height: 1;
    }
    
    p.contact-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    form.contact-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    .contact-input {
        margin-bottom: 20px;
    }
    
    p.contact-accept-privacy {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
    
    button.contact-submit-btn {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        width: 100%;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
    }

    button.contact-submit-btn:hover {
        background-color: #393939;
    }

    .group-foto-area {
        margin-top: 50px; /* Adjust the value as needed */
        overflow: hidden;
        border-radius: 30px;
    }

    .group-foto {
        object-fit: contain;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    section.contact-view-section {
        width: 100%;
        min-height: 100vh;
        padding-top: 100px;
        padding-bottom: 50px;
        box-sizing: border-box;
        overflow: hidden;
    }
    
    div#contact-map {
        width: 90%;
        height: 200px;
        background-color: grey;
        float: left;
        overflow: hidden;
        border-radius: 50px;
        margin-left: 5%;
    }
    
    div#contact-info-content-area {
        width: 100%;
        height: 100%;
        float: left;
        padding: 0 5%;
        margin-top: 50px;
    }
    
    h1.contact-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
        line-height: 1;
    }
    
    p.contact-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    form.contact-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    .contact-input {
        margin-bottom: 20px;
    }
    
    p.contact-accept-privacy {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
    
    button.contact-submit-btn {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        width: 100%;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
    }

    button.contact-submit-btn:hover {
        background-color: #393939;
    }

    .group-foto-area {
        margin-top: 50px; /* Adjust the value as needed */
        overflow: hidden;
        border-radius: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    section.contact-view-section {
        width: 100%;
        min-height: 100vh;
        padding-top: 100px;
        padding-bottom: 50px;
        box-sizing: border-box;
        overflow: hidden;
    }
    
    div#contact-map {
        width: 90%;
        height: 200px;
        background-color: grey;
        float: left;
        overflow: hidden;
        border-radius: 50px;
        margin-left: 5%;
    }
    
    div#contact-info-content-area {
        width: 100%;
        height: 100%;
        float: left;
        padding: 0 5%;
        margin-top: 50px;
    }
    
    h1.contact-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
        line-height: 1;
    }
    
    p.contact-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    form.contact-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    .contact-input {
        margin-bottom: 20px;
    }
    
    p.contact-accept-privacy {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
    
    button.contact-submit-btn {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        width: 100%;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
    }

    button.contact-submit-btn:hover {
        background-color: #393939;
    }
    .group-foto-area {
        margin-top: 50px; /* Adjust the value as needed */
        overflow: hidden;
        border-radius: 30px;
    }
    .group-foto {
        object-fit: contain;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    section.contact-view-section {
        width: 100%;
        min-height: 100vh;
        padding-top: 100px;
        padding-bottom: 50px;
        box-sizing: border-box;
        overflow: hidden;
    }
    
    div#contact-map {
        width: 90%;
        height: 200px;
        background-color: grey;
        float: left;
        overflow: hidden;
        border-radius: 50px;
        margin-left: 5%;
    }
    
    div#contact-info-content-area {
        width: 100%;
        height: 100%;
        float: left;
        padding: 0 5%;
        margin-top: 50px;
    }
    
    h1.contact-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
        line-height: 1;
    }
    
    p.contact-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    form.contact-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    .contact-input {
        margin-bottom: 20px;
    }
    
    p.contact-accept-privacy {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
    
    button.contact-submit-btn {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        width: 100%;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
    }

    button.contact-submit-btn:hover {
        background-color: #393939;
    }
    .group-foto-area {
        margin-top: 50px; /* Adjust the value as needed */
        overflow: hidden;
        border-radius: 30px;
    }
    .group-foto {
        object-fit: contain;
    }
}

@media (min-width: 1200px) {
    section.contact-view-section {
        width: 100%;
        min-height: 100vh;
        padding-top: 100px;
        padding-bottom: 50px;
        box-sizing: border-box;
        overflow: hidden;
    }
    
    div#contact-map {
        width: 50%;
        height: calc(100vh - 200px);
        float: left;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        overflow: hidden;
    }
    
    div#contact-info-content-area {
        width: 50%;
        height: 100%;
        float: left;
        padding: 0 100px;
    }
    
    h1.contact-title-h1 {
        font-family: 'SF Pro Display';
        font-size: 40px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    p.contact-txt-p {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    
    form.contact-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    .contact-input {
        margin-bottom: 20px;
    }
    
    p.contact-accept-privacy {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
    
    button.contact-submit-btn {
        font-family: 'SF Pro Display';
        font-size: 1.2em;
        color: white;
        margin: 0;
        padding: 0;
        border: none;
        width: 100%;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
    }
    .group-foto-area {
        margin-top: 50px; /* Adjust the value as needed */
        overflow: hidden;
        border-radius: 30px;
    }
    .group-foto {
        object-fit: contain;
    }
}