@media (max-width: 575.98px) {
    div.project-page-area {
        padding-left: 5%;
        padding-right: 5%;
        min-height: 100vh;
        padding-top: 100px;
    }

    h1.main-project-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2.4em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }

    div.project-main-img-area {
        width: 100%;
        overflow: hidden;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.project-main-img {
        width: 100%;
    }

    div.project-main-content-area {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 50px;
    }

    p.project-main-introductie-title-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }

    p.project-main-introductie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
    }

    div.project-main-info-table-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    div.project-main-info-table-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 40px;
    }

    div.project-main-info-left-row {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    div.project-main-info-right-row {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    p.project-main-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    p.project-main-info-val-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
    }

    ul.project-main-info-val-listing-ul {
        width: 100%;
        height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.project-main-info-val-listing-li {
        height: 100%;
        float: left;
        margin-right: 5px;
    }

    img.project-main-info-val-img {
        height: 100%;
    }

    img.project-main-persoon-val-img {
        width: 50px;
        height: 100%;
        border-radius: 50px;
        object-fit: cover;
    }

    div.project-main-mockups-viewer-area {
        width: 100%;
        height: 800px;
        overflow: hidden;
        margin-bottom: 50px;
        background-color: black;
        padding-top: 100px;
        padding-bottom: 100px;
        box-sizing: border-box;
    }

    img.swiper-mockup-img {
        height: 100%;
        width: auto;
    }

    div.swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        width: auto !important;
        height: 100%;
        display: inline-block;
    }

    div.project-main-languages-used-area {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 15%;
        padding-right: 15%;
    }

    div.project-main-languages-inner-div {
        width: 70%;
        margin-left: 15%;
        display: grid;
        /* grid:  */
    }

    .show-website-button button {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid transparent;
    }

    .show-website-button button:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;
    }


}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.project-page-area {
        padding-left: 5%;
        padding-right: 5%;
        min-height: 100vh;
        padding-top: 100px;
    }

    h1.main-project-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2.4em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }

    div.project-main-img-area {
        width: 100%;
        overflow: hidden;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.project-main-img {
        width: 100%;
    }

    img.project-main-img-locus {
        height: 400px;
        width: auto;
        margin: 0;
    }

    div.project-main-content-area {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 50px;
    }

    p.project-main-introductie-title-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }

    p.project-main-introductie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
    }

    div.project-main-info-table-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    div.project-main-info-table-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 40px;
    }

    div.project-main-info-left-row {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    div.project-main-info-right-row {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    p.project-main-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    p.project-main-info-val-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
    }

    ul.project-main-info-val-listing-ul {
        width: 100%;
        height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.project-main-info-val-listing-li {
        height: 100%;
        float: left;
        margin-right: 5px;
    }

    img.project-main-info-val-img {
        height: 100%;
    }

    img.project-main-persoon-val-img {
        width: 50px;
        height: 100%;
        border-radius: 50px;
        object-fit: cover;
    }

    div.project-main-mockups-viewer-area {
        width: 100%;
        height: 800px;
        overflow: hidden;
        margin-bottom: 50px;
        background-color: black;
        padding-top: 100px;
        padding-bottom: 100px;
        box-sizing: border-box;
    }

    img.swiper-mockup-img {
        height: 100%;
        width: auto;
    }

    div.swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        width: auto !important;
        height: 100%;
        display: inline-block;
    }

    div.project-main-languages-used-area {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 15%;
        padding-right: 15%;
    }

    div.project-main-languages-inner-div {
        width: 70%;
        margin-left: 15%;
        display: grid;
        /* grid:  */
    }

    .show-website-button button {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid transparent;
    }

    .show-website-button button:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.project-page-area {
        padding-left: 5%;
        padding-right: 5%;
        min-height: 100vh;
        padding-top: 100px;
    }

    h1.main-project-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 2.4em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
    }

    div.project-main-img-area {
        width: 100%;
        overflow: hidden;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.project-main-img {
        width: 100%;
    }

    img.project-main-img-locus {
        height: 400px;
        width: auto;
        margin: 0;
    }

    div.project-main-content-area {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 50px;
    }

    p.project-main-introductie-title-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }

    p.project-main-introductie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
    }

    div.project-main-info-table-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    div.project-main-info-table-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 40px;
    }

    div.project-main-info-left-row {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    div.project-main-info-right-row {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    p.project-main-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    p.project-main-info-val-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
    }

    ul.project-main-info-val-listing-ul {
        width: 100%;
        height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.project-main-info-val-listing-li {
        height: 100%;
        float: left;
        margin-right: 5px;
    }

    img.project-main-info-val-img {
        height: 100%;
    }

    img.project-main-persoon-val-img {
        width: 50px;
        height: 100%;
        border-radius: 50px;
        object-fit: cover;
    }

    div.project-main-mockups-viewer-area {
        width: 100%;
        height: 800px;
        overflow: hidden;
        margin-bottom: 50px;
        background-color: black;
        padding-top: 100px;
        padding-bottom: 100px;
        box-sizing: border-box;
    }

    img.swiper-mockup-img {
        height: 100%;
        width: auto;
    }

    div.swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        width: auto !important;
        height: 100%;
        display: inline-block;
    }

    div.project-main-languages-used-area {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 15%;
        padding-right: 15%;
    }

    div.project-main-languages-inner-div {
        width: 70%;
        margin-left: 15%;
        display: grid;
        /* grid:  */
    }

    .show-website-button button {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid transparent;
    }

    .show-website-button button:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.project-page-area {
        padding-left: 5%;
        padding-right: 5%;
        min-height: 100vh;
        padding-top: 100px;
    }

    h1.main-project-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 3em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
        font-weight: bold;
    }

    div.project-main-img-area {
        width: 100%;
        min-height: 500px;
        overflow: hidden;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.project-main-img {
        width: 85%;
        margin-top: 50px;
    }

    img.project-main-img-locus {
        height: 400px;
        width: auto;
        margin: 0;
    }

    div.project-main-content-area {
        width: 85%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 50px;
        margin-left: 7.5%;
    }

    p.project-main-introductie-title-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }

    p.project-main-introductie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
    }

    div.project-main-info-table-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    div.project-main-info-table-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 40px;
    }

    div.project-main-info-left-row {
        width: 50%;
        height: 100%;
        float: left;
    }

    div.project-main-info-right-row {
        width: 50%;
        height: 100%;
        float: left;
    }

    p.project-main-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    p.project-main-info-val-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
    }

    ul.project-main-info-val-listing-ul {
        width: 100%;
        height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.project-main-info-val-listing-li {
        height: 100%;
        float: left;
        margin-right: 5px;
    }

    img.project-main-info-val-img {
        height: 100%;
    }

    img.project-main-persoon-val-img {
        width: 50px;
        height: 100%;
        border-radius: 50px;
        object-fit: cover;
    }

    div.project-main-mockups-viewer-area {
        width: 100%;
        height: 800px;
        overflow: hidden;
        margin-bottom: 50px;
        background-color: black;
        padding-top: 100px;
        padding-bottom: 100px;
        box-sizing: border-box;
    }

    img.swiper-mockup-img {
        height: 100%;
        width: auto;
    }

    div.swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        width: auto !important;
        height: 100%;
        display: inline-block;
    }

    div.project-main-languages-used-area {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 15%;
        padding-right: 15%;
    }

    div.project-main-languages-inner-div {
        width: 70%;
        margin-left: 15%;
        display: grid;
        /* grid:  */
    }

    .show-website-button button {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid transparent;
    }

    .show-website-button button:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;
    }
}

@media (min-width: 1200px) {
    div.project-page-area {
        padding-left: 15%;
        padding-right: 15%;
        min-height: 100vh;
        padding-top: 100px;
    }

    div.project-page-info-bar {
        width: 100%;
        min-height: 50px;
        position: relative;
    }

    button.project-page-prev-btn {
        border: none;
        border-radius: 50px;
        cursor: pointer;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: absolute;
        left: 0;
    }

    button.project-page-prev-btn:hover {
        background-color: #f1f1f1;
    }

    h1.main-project-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 3em;
        color: black;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        text-align: center;
        font-weight: bold;
    }

    div.project-main-img-area {
        width: 100%;
        min-height: 500px;
        overflow: hidden;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.project-main-img {
        width: 70%;
        margin-top: 50px;
    }

    img.project-main-img-locus {
        height: 400px;
        width: auto;
        margin: 0;
    }

    div.project-main-content-area {
        width: 70%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 50px;
        margin-left: 15%;
    }

    p.project-main-introductie-title-txt-p {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }

    p.project-main-introductie-txt-p {
        font-family: 'SF Pro Display';
        font-size: 20px;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
    }

    div.project-main-info-table-div {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    div.project-main-info-table-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 20px;
        float: left;
    }

    div.project-main-info-left-row {
        width: 50%;
        height: 100%;
        float: left;
    }

    div.project-main-info-right-row {
        width: 50%;
        height: 100%;
        float: left;
    }

    p.project-main-info-var-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: #7b7b7b;
        margin: 0;
        padding: 0;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    p.project-main-info-val-txt-p {
        font-family: 'SF Pro Display';
        font-size: 18px;
        color: black;
        margin: 0;
        padding: 0;
    }

    ul.project-main-info-val-listing-ul {
        width: 100%;
        height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.project-main-info-val-listing-li {
        height: 100%;
        float: left;
        margin-right: 5px;
    }

    img.project-main-info-val-img {
        height: 100%;
    }

    img.project-main-persoon-val-img {
        width: 50px;
        height: 100%;
        border-radius: 50px;
        object-fit: cover;
    }

    div.project-main-mockups-viewer-area {
        width: 100%;
        height: 800px;
        overflow: hidden;
        margin-bottom: 50px;
        background-color: black;
        padding-top: 100px;
        padding-bottom: 100px;
        box-sizing: border-box;
    }

    img.swiper-mockup-img {
        height: 100%;
        width: auto;
    }

    div.swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        width: auto !important;
        height: 100%;
        display: inline-block;
    }

    div.project-main-languages-used-area {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 15%;
        padding-right: 15%;
    }

    div.project-main-languages-inner-div {
        width: 70%;
        margin-left: 15%;
        display: grid;
        /* grid:  */
    }

    .main-project-title-h1 a {
        text-decoration: none;
        color: inherit; 
        transition: color 0.3s ease; 
      }

      .show-website-button button {
        font-family: 'SF Pro Display';
        font-size: 16px;
        color: white;
        margin: 0;
        padding: 0;
        padding: 10px 20px;
        background-color: black;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid transparent;
    }

    .show-website-button button:hover {
        background-color: white;
        color: black;
        border-color: black;
        border: 1px solid black;
    }
}